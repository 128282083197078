import "core-js/stable";
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Form from 'react-formal';
import FormComponent from '../../base/components/form-component';
import FormGroup from './../../app/components/form-group';
import serviceLocator from '../../base/service/service-locator';
import FormattedCurrency from './../../base/components/formatted-currency';
import DatePicker, { schema as dateSchema } from './../../base/form/fields/date-picker.js';
import SelectCurrency,
  { schema as selectCurrencySchema } from './../../base/form/fields/select-currency';
import Loader from './../../app/components/loader';
import Config from 'Config';

export default class Statements extends FormComponent {
  utilsDate = serviceLocator.get('UtilsDate');

  constructor(props) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const monthAgo = new Date(today);
    monthAgo.setMonth(monthAgo.getMonth() - 1);

    const schema = {
      dateFrom: dateSchema.max(today, Config.validationMessages.dateInFuture),
      dateTo: dateSchema.max(today, Config.validationMessages.dateInFuture),
      currency: selectCurrencySchema,
    };

    const state = {
      currencies: [],
      statementsList: [],
      isDatePeriodValid: true,
      isLoading: true,
      value: {
        dateFrom: monthAgo,  // month ago
        dateTo: today,
      },
      statementsCurrency: null,
    };

    super(props, state, schema);

    this.statementsGateway = serviceLocator.get('StatementsGateway');
    this.balancesGateway = serviceLocator.get('BalancesGateway');

    this.utils = serviceLocator.get('Utils');
  }

  componentDidMount() {
    this.getAvailableCurrencies().then((currencies) => {
      if (currencies.length === 0) {
        this.setState({
          isLoading: false,
        });
        return;
      }

      const value = Object.assign({}, this.state.value);
      let statementsCurrency;

      if (this.props.location &&
        this.props.location.query &&
        this.props.location.query.currency) {
        statementsCurrency = currencies.find(c => c.value === this.props.location.query.currency);
      } else {
        statementsCurrency = currencies[0];
      }
      value.currency = statementsCurrency;

      this.setState({
        currencies,
        value,
        statementsCurrency,
        isLoading: false,
      });
      this.getStatementsList();
    });
  }

  formOnChange = (value, updatedPaths, onValueUpdateHandler = null) => {
    this._formOnChange(value, updatedPaths, onValueUpdateHandler);
    this.setState({ isDatePeriodValid: value.dateFrom <= value.dateTo });
  }

  formOnSubmit = (fields) => {
    this._formOnSubmit(fields);
    this.setState({
      statementsList: [],
      statementsCurrency: Object.assign({}, this.state.value.currency),
    }, () => {
      this.getStatementsList();
    });
  };

  /**
   * Get statements list from the backend
   *
   * @returns {*}
   */
  getStatementsList() {
    if (!this.state.value) {
      this.setState({
        statementsList: [],
        isLoading: false,
      });
      return;
    }

    this.setState({
      isLoading: true,
    });

    this.statementsGateway.get({
      currency: this.state.value.currency.value,
      bankDateFrom: this.utilsDate.getFormatedDate(this.state.value.dateFrom),
      bankDateTo: this.utilsDate.getFormatedDate(this.state.value.dateTo),
    }).then(data => {
      if (!this.utils.isComponentMounted(this)) {
        return;
      }
      this.setState({
        statementsList: data,
        isLoading: false,
      });
    }).catch(error => {
      if (this.logger.handlePromiseCatch(error)) {
        return;
      }
      this.setState({ error });
    });
  }

  getAvailableCurrencies() {
    return this.balancesGateway.get().then((data) => (
      data.map((val) => (
        {
          value: val.currency,
          label: val.currency,
        }
      ))
    ));
  }

  renderForm() {
    return (
      <Form
        ref="form"
        component="div"
        className="form form-statements-filter"
        schema={this.schema}
        value={this.state.value}
        errors={this.state.formErrors}
        onError={this.formOnError}
        onChange={this.formOnChange}
        onSubmit={this.formOnSubmit}
      >
        <div className="row">
          <div className="col-sm-4">
            <DatePicker
              name="dateFrom"
              label="From Date"
              required="Please enter start date"
              placeholder="DD/MM/YYYY"
              className="form-control input-sm"
              icons
              max={new Date()}
            />
          </div>

          <div className="col-sm-4">
            <DatePicker
              name="dateTo"
              label="To Date"
              required="Please enter end date"
              placeholder="DD/MM/YYYY"
              className="form-control input-sm"
              icons
              max={new Date()}
            />
          </div>

          <div className="col-sm-4">
            <FormGroup
              fieldName="buyCurrency"
              icons
              formGroupClassName="form-group-select form-group-sm"
            >
              <SelectCurrency
                id="currency"
                name="currency"
                options={this.state.currencies}
                className="input-sm currency-list"
                placeholder="Currency"
              />
            </FormGroup>
          </div>
        </div>

        <div className="form-group form-group-btn text-center">
          <ReactCSSTransitionGroup
            component="div"
            transitionName="form-group-message"
            transitionEnterTimeout={Config.transitionTimeout.opacity}
            transitionLeaveTimeout={Config.transitionTimeout.opacity}
          >
            {
              !this.state.isDatePeriodValid
              ? <div className="form-group-message alert-danger">
                {Config.validationMessages.dateOrder}
              </div>
              : null
            }
          </ReactCSSTransitionGroup>

          <Form.Button
            type="submit"
            className="btn btn-sm btn-info load-statements"
            disabled={this.state.isLoading || !this.state.isDatePeriodValid}
          >Submit</Form.Button>
        </div>
      </Form>);
  }

  renderTable() {
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Bank Date</th>
              <th>Description</th>
              <th className="text-right">Debit</th>
              <th className="text-right">Credit</th>
              <th className="text-right">Balance</th>
            </tr>
          </thead>

          <tbody>
            {this.state.statementsList.map((statement) => (
              <tr key={statement.transactionId}>
                <td>{this.utilsDate.getUKDate(statement.bankDate)}</td>
                <td>{statement.description}</td>
                <td className="text-right">
                  {
                    typeof statement.debit === 'number'
                    ? <FormattedCurrency
                      value={statement.debit}
                      currency={this.state.statementsCurrency.value}
                    />
                    : null
                  }
                </td>
                <td className="text-right">
                  {
                    typeof statement.credit === 'number'
                    ? <FormattedCurrency
                      value={statement.credit}
                      currency={this.state.statementsCurrency.value}
                    />
                    : null
                  }
                </td>
                <td className="text-right">
                  <FormattedCurrency
                    value={statement.balance || 0}
                    currency={this.state.statementsCurrency.value}
                  />
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>);
  }

  render() {
    return (
      <div className="tab-content platform-tabs">
        <section className="tab-pane fade in active tab-statements" id="tab-statements">
          <div className="clearfix">
            <div className="col-md-8 col-md-offset-2">
              <h2>Statements</h2>

              {this.state.currencies.length > 0 ? this.renderForm() : null}

              <ReactCSSTransitionGroup
                component="div"
                transitionName="table-responsive"
                transitionEnterTimeout={Config.transitionTimeout.opacity}
                transitionLeaveTimeout={Config.transitionTimeout.opacity}
              >
                {
                  this.state.statementsList.length && this.state.currencies.length
                  ? this.renderTable()
                  : null
                }
              </ReactCSSTransitionGroup>

              {
                (!this.state.statementsList.length && !this.state.isLoading)
                ? <h6 className="text-center">There are no available statements</h6>
                : null
              }

              {this.state.isLoading ? <Loader /> : null}
            </div>
          </div>
        </section>
      </div>);
  }
}
