import jwtDecode from 'jwt-decode';

import serviceLocator from './../base/service/service-locator';

import Auth from './models/auth';
import Jwt from './models/jwt';
import AclService from './service/acl';
import DataModel from './../base/models/data-model.js';

export default () => {
  serviceLocator.register('Jwt', Jwt, [window.sessionStorage, jwtDecode]);

  serviceLocator.register('AclGateway', DataModel, ['acl'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('AclService', AclService, ['acl'])
    .dependencies('dataClient=HttpClientAdapter,auth=Auth,jwt=Jwt');

  serviceLocator.register('Auth', Auth)
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt,userDetailsGateway=UserCurrentGateway,utils=Utils');

  serviceLocator.register('GoogleAuthenticatorModel', DataModel, ['user/otp-verify'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('UserCurrentGateway', DataModel, ['user/current'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('ChangePasswordGateway', DataModel, ['user/details'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('UserPersonalGateway', DataModel, ['user/personal'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('UserAddRequestGateway', DataModel, ['user/add-request'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('UserFileRequestsGateway', DataModel, ['file-upload/v1/document-requests'])
    .dependencies('dataClient=HttpApiClientAdapter,jwt=Jwt');

  serviceLocator.register('ResetPasswordStep1Gateway', DataModel, ['reset-password-step1'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('ResetPasswordStep2Gateway', DataModel, ['reset-password-step2'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  serviceLocator.register('CheckTokenGateway', DataModel, ['reset-password-check-token'])
    .dependencies('dataClient=HttpClientAdapter,jwt=Jwt');

  document.addEventListener('onAppReady', () => {
    const aclService = serviceLocator.get('AclService');
    aclService.bootstrap();
  });
};
