import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import serviceLocator from '../../base/service/service-locator';
import UpdatableComponent from '../../base/components/updatable-component';
import InfiniteLoad from './../../base/components/infinite-load';
import ConfirmPopup from './../../app/components/confirm-popup';
import Loader from './../../app/components/loader';
import BeneficiaryFormModal from './beneficiary-form-modal';
import Config from 'Config';
import {Link} from 'react-router';

export default class BeneficiaryList extends UpdatableComponent {
  static propTypes = {
    limit: React.PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      limit: props.limit || 10,
      beneficiaryList: [],
      isLoading: true,
      infiniteLoadingEnabled: true,
      showConfirmation: false,
      selectedBeneficiaryId: null,
      showBeneficiaryForm: false,
      enteredMobile: false,
    };

    this.countryList = [];

    this.onCancelDeleteBeneficiary = this.onCancelDeleteBeneficiary.bind(this);
    this.deleteBeneficiary = this.deleteBeneficiary.bind(this);
    this.onEditBeneficiary = this.onEditBeneficiary.bind(this);
    this.onDeleteBeneficiary = this.onDeleteBeneficiary.bind(this);
    this.onClickBeneficiary = this.onClickBeneficiary.bind(this);
  }

  componentDidMount() {
    this.loadData();
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }


  onAddBeneficiary = () => {
    this.setState({
      showBeneficiaryForm: true,
      selectedBeneficiaryId: -1,
    });
  };

  onEditBeneficiary = (beneficiaryId) => {
    this.setState({
      showBeneficiaryForm: true,
      selectedBeneficiaryId: beneficiaryId,
    });
  };

  onDeleteBeneficiary = (beneficiaryId) => {
    this.setState({
      showConfirmation: true,
      selectedBeneficiaryId: beneficiaryId,
    });
  };

  onCancelDeleteBeneficiary = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  onClickBeneficiary = (beneficiaryId) => (e) => {
    e.preventDefault();
    this.setState((state) => ({
      expandedBeneficiaryId: state.selectedBeneficiaryId !== beneficiaryId,
      selectedBeneficiaryId: beneficiaryId,
    }));
  };

  onResize = () => {
    this.setState({
      enteredMobile: this.utils.isMobileDevice() || window.innerWidth < 769,
    });
  };

  formatBeneficiaryList(beneficiaryList) {
    return beneficiaryList.items.map(beneficiary => {
      // eslint-disable-next-line no-param-reassign
      beneficiary.country = this.countryList.filter(el =>
        el.value === beneficiary.destinationCountryId);
      return beneficiary;
    });
  }

  /**
   * Get beneficiary list from defined page
   */
  getBeneficiaryList = () => {
    this.setState({
      isLoading: true,
    });

    this.beneficiaryGateway
      .get({
        per_page: this.state.limit,
        page: this.state.page,
      })
      .then(data => {
        if (!this.utils.isComponentMounted(this)) {
          return;
        }

        const beneficiaryList = this.formatBeneficiaryList(data);

        const nextState = {
          beneficiaryList: this.state.beneficiaryList.concat(beneficiaryList),
          page: this.state.page + 1,
          isLoading: false,
        };

        if (this.state.page * this.state.limit >= data.count) {
          nextState.infiniteLoadingEnabled = false;
        }

        this.setState(nextState);
      })
      .catch(error => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        this.setState({
          error,
          infiniteLoadingEnabled: false,
        });
      });
  };

  loadData() {
    this.countryGateway.getList(countryList => {
      if (!this.utils.isComponentMounted(this)) {
        return;
      }
      this.countryList = countryList;
      this.getBeneficiaryList();
    });
  }



  /**
   * Hide modal popup and reload beneficiary list when beneficiary form was saved
   */
  onSaveBeneficiary = () => {
    this.beneficiaryGateway
      .get({
        per_page: this.state.limit * this.state.page,
        page: 1,
      })
      .then(data => {
        this.setState({
          beneficiaryList: this.formatBeneficiaryList(data),
          showBeneficiaryForm: false,
          selectedBeneficiaryId: null,
        });
      })
      .catch(error => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        this.setState({
          error,
          infiniteLoadingEnabled: false,
        });
      });
  };

  /**
   * Hide modal popup on cancel beneficiary form
   */
  onCancelBeneficiaryForm = () => {
    this.setState({
      showBeneficiaryForm: false,
      selectedBeneficiaryId: null,
    });
  };

  countryGateway = serviceLocator.get('CountryGateway');
  beneficiaryGateway = serviceLocator.get('BeneficiaryGateway');
  utils = serviceLocator.get('Utils');
  logger = serviceLocator.get('Logger');

  deleteBeneficiary() {
    const beneficiaryId = parseInt(this.state.selectedBeneficiaryId, 10);
    this.setState({
      isLoading: true,
      showConfirmation: false,
    });

    this.beneficiaryGateway
      .del(beneficiaryId)
      .then(() => {
        const beneficiaryList = this.state.beneficiaryList;

        beneficiaryList.forEach((beneficiary, i) => {
          if (parseInt(beneficiary.id, 10) === beneficiaryId) {
            beneficiaryList.splice(i, 1);
          }
        });

        this.setState({
          beneficiaryList,
          selectedBeneficiaryId: null,
          isLoading: false,
        });
      });
  }

  renderTable() {
    return (
      <div className="table-responsive" key="1">
        <InfiniteLoad
          scrollHandler={this.getBeneficiaryList}
          isEnabled={this.state.infiniteLoadingEnabled}
          isLoading={this.state.isLoading}
          bottomOffset={Config.infiniteLoadBottomOffsetMore}
        >
            <table className="table">
            {!this.state.enteredMobile ?
              <thead>
                <tr className="first-row">
                  <th className="recipient-name">Recipients Name</th>
                  <th className="account-name">Account Number</th>
                  <th>Country</th>
                  <th>Currency</th>
                  <th>Confirmed</th>
                  <th className="actions">Actions</th>
                </tr>
              </thead>
              :
              <thead>
                <tr>
                  <th>Recipients</th>
                  <th className="account-cell">Account Number</th>
                </tr>
              </thead>
            }
            <tbody>
            {!this.state.enteredMobile ? this.state.beneficiaryList.map((b, i) => (
              <tr key={i} className="expandable-row">
                <td className="recipient-name">{b.name}</td>
                <td className="account-name">{b.accountNumber}</td>
                <td>{b.country[0].label}</td>
                <td>{b.currency}</td>
                <td>{b.confirmed === '1' || b.confirmed === '2' ? 'Yes' : 'No'}</td>
                <td>
                    <table className="nested-table">
                        <thead>
                            <tr>
                                {b.confirmed === '1' || b.confirmed === '2'
                                  ? null
                                  : <td>
                                      <Link onClick={() => this.onEditBeneficiary(b.id)} className="icon-linked edit">Edit</Link>
                                    </td>
                                }
                                <td>
                                    <Link onClick={() => this.onDeleteBeneficiary(b.id)} className="icon-linked delete">Delete</Link>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </td>
              </tr>))
              :
              this.state.beneficiaryList.map((b, i) => (
                <tr key={i}>
                  <td colSpan="3" className={"expandable-row-mobile " + (this.state.selectedBeneficiaryId === b.id && this.state.expandedBeneficiaryId ? 'is-active' : '')}>
                    <table>
                      <thead>
                        <tr className="first-row">
                          <td className="recipient-name">{b.name}</td>
                          <td className="account-name">{b.accountNumber}</td>
                          <td className="menu-button">
                            <button className={"hamburger hamburger--slider " + (this.state.selectedBeneficiaryId === b.id && this.state.expandedBeneficiaryId ? 'is-active' : '')} onClick={this.onClickBeneficiary(b.id)} type="button">
                              <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                              </span>
                            </button>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-bold">
                          <td>Country</td>
                          <td>Currency</td>
                          <td>Confirmed</td>
                        </tr>
                        <tr>
                          <td>{b.country[0].label}</td>
                          <td>{b.currency}</td>
                          <td>{b.confirmed === '1' || b.confirmed === '2' ? 'Yes' : 'No'}</td>
                        </tr>
                        <tr>
                          <td className="text-bold">Action</td>
                          <td>
                            <Link onClick={() => this.onEditBeneficiary(b.id)} className="icon-linked edit">Edit</Link>
                          </td>
                          <td>
                            <Link onClick={() => this.onDeleteBeneficiary(b.id)} className="icon-linked delete">Delete</Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </InfiniteLoad>
        {this.state.infiniteLoadingEnabled ?
            <button className="load-more btn btn-lg btn-primary" onClick={this.getBeneficiaryList} disabled={this.state.isLoading}>Load more</button>
            : null
        }
      </div>
    );
  }

  render() {

    return (
      <div className="tab-content platform-tabs">
        <section className="tab-pane fade in active tab-beneficiaries" id="tab-beneficiaries">
          <div className="clearfix">
            <div className="col-md-12 col-md-offset-0">
              <h2>Recipients</h2>

              <div className="form-group text-right">
                <input
                  type="button"
                  className="icon add-recipients"
                  onClick={this.onAddBeneficiary}
                  value="Add new"
                />
              </div>

              <ReactCSSTransitionGroup
                component="div"
                transitionName="table-responsive"
                transitionEnterTimeout={Config.transitionTimeout.opacity}
                transitionLeaveTimeout={Config.transitionTimeout.opacity}
              >
                {this.state.beneficiaryList.length > 0 ? this.renderTable() : null}
              </ReactCSSTransitionGroup>

              {
                (!this.state.beneficiaryList.length && !this.state.isLoading)
                ? <h6 className="text-center">No beneficiaries have been made.</h6>
                : null
              }

              {this.state.isLoading ? <Loader /> : null}

              <ConfirmPopup
                text="Are you sure?"
                onConfirm={this.deleteBeneficiary}
                onCancel={this.onCancelDeleteBeneficiary}
                showModal={this.state.showConfirmation}
              />

              {
                this.state.showBeneficiaryForm
                ? <BeneficiaryFormModal
                  beneficiaryId={this.state.selectedBeneficiaryId}
                  onSave={this.onSaveBeneficiary}
                  onCancel={this.onCancelBeneficiaryForm}
                />
                : null
              }
            </div>
          </div>
        </section>
      </div>);
  }
}
