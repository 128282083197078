import "core-js/stable";
import React from 'react';
import UpdatableComponent from './../../base/components/updatable-component';
import serviceLocator from '../../base/service/service-locator';
import Quote from './quote';
import QuoteReview from './quote-review';
import QuoteConfirmation from './quote-confirmation';
import { TYPE_DEBIT, TYPE_CREDIT, TYPE_TRANSFER } from './../../payment/components/payment';
import Loader from './../../app/components/loader';
import Config from 'Config';
import ticker from './../quote-ticker';
import ModalQuoteExpired from './modal-quote-expired';
import QuotesInProgress from './quotes-in-progress';
import {METHOD_DEBIT} from "../../payment/components/payment";

const QUOTE_STORAGE = 'quoteValues';
const SENDOUT_STORAGE = 'sendOutValues';

export default class MakeTransferStep1 extends UpdatableComponent {
  static propTypes = {
    onStepComplete: React.PropTypes.func,
    onSendOutValuesChange: React.PropTypes.func.isRequired,
    onStartBooking: React.PropTypes.func.isRequired,
    onBookingError: React.PropTypes.func.isRequired,
  };

  static contextTypes = {
    showErrorModal: React.PropTypes.func,
  };

  static childContextTypes = {
    setPaymentResult: React.PropTypes.func,
  };

  acl = serviceLocator.get('AclService');
  makeTransferGateway = serviceLocator.get('MakeTransferGateway');
  currencyGateway = serviceLocator.get('CurrencyGateway');
  utils = serviceLocator.get('Utils');
  logger = serviceLocator.get('Logger');
  ga = serviceLocator.get('GA');

  worldpayScriptId = 'worldpayScript';

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      quoteValues: null,
      cardStatus: null,
      quoteTimerEnabled: false, /* Is quote timer ticking? */
      quoteTimeout: 300,
      quoteReviewed: false,
      paymentInProgress: false,
      showCardTransfer: false,
      cardType: null,
      showQuoteExpiredModal: false,
      creditCardChargeApplied: false,
    };
  }

  getChildContext() {
    return {
      setPaymentResult: this.setBookingState,
    };
  }

  componentWillMount() {
    this.addWorldpayScript();
    this.restoreQuote();
  }

  componentWillUnmount() {
    this.removeWorldpayScript();
  }

  removeWorldpayScript() {
    const worldpayScript = document.getElementById(this.worldpayScriptId);
    if (worldpayScript) {
      worldpayScript.parentNode.removeChild(worldpayScript);
    }
  }

  addWorldpayScript() {
    ((e, id) => {
      const t = document;
      const n = t.createElement('script');
      n.async = !0, n.defer = !0, n.id = id, n.src = e, t.getElementsByTagName('head')[0].appendChild(n);
    })(Config.worldpayLegacy.formScript, this.worldpayScriptId);
  }

  /* -------------- Quote ------------------ */

  onQuoteValuesSet = (values) => {
    if (!this.utils.isComponentMounted(this)) {
      return;
    }

    if (values) {
      const quoteValues = this.calculateTotalAmount(values);
      let showCardTransfer = quoteValues.sellCurrency ?
      this.isCardTransferEnabled(quoteValues.sellCurrency) : false;

      if (!Config.worldpayLegacy.CardPaymentEnabled) {
        showCardTransfer = false;
      }

      if (!quoteValues.paymentMethods || quoteValues.paymentMethods.indexOf(METHOD_DEBIT) === -1) {
        showCardTransfer = false;
      }

      const cardType = this.calcCardType(showCardTransfer);
      this.onChangeCardType(cardType);
      this.setState({
        quoteValues,
        cardStatus: null,
        quoteAvailable: true,
        quoteLoading: false,
        quoteReviewed: false,
        showCardTransfer,
        quoteTimerEnabled: true,
        quoteTimeout: values.timeout,
      });

      ticker.destroy();
      ticker.start(values.timeout, 1000);
      ticker.register(this.tickTimer);

      if (sessionStorage) {
        sessionStorage.setItem(QUOTE_STORAGE, JSON.stringify(quoteValues));
      }
    } else {
      this.setState({
        quoteValues: null,
        cardStatus: null,
        quoteTimerEnabled: false,
        isLoading: false,
        cardType: this.state.cardType === TYPE_TRANSFER ? null : this.state.cardType,
      });
      this.props.onSendOutValuesChange(null);
      ticker.destroy();

      if (sessionStorage) {
        sessionStorage.removeItem(QUOTE_STORAGE);
      }
    }
  };

  restoreQuote = () => {
    const { location } = this.props;
    if (location && location.query && location.query.status && sessionStorage && sessionStorage.hasOwnProperty(QUOTE_STORAGE)) {
      const quoteValues = JSON.parse(sessionStorage.getItem(QUOTE_STORAGE));

      let smallSendOutValues = null;
      if (sessionStorage.hasOwnProperty(SENDOUT_STORAGE)) {
        smallSendOutValues = JSON.parse(sessionStorage.getItem(SENDOUT_STORAGE));
        this.props.onSendOutValuesChange(smallSendOutValues);
        sessionStorage.removeItem(SENDOUT_STORAGE);
      }

      this.setState({
        quoteValues,
        smallSendOutValues,
        quoteAvailable: true,
        quoteLoading: false,
        quoteReviewed: true,
        showCardTransfer: true,
        quoteTimerEnabled: false,
        cardStatus: location.query.status,
      });

      sessionStorage.removeItem(QUOTE_STORAGE);
    }
  };

  calculateTotalAmount = (quoteValues) => {
    let totalFees = 0;

    quoteValues.appliedFees = {};
    Object.keys(quoteValues.fees).forEach(key => {
      const feeAmount = parseFloat(quoteValues.fees[key]);

      // If we have state <key>ChargeApplied
      if (this.state[`${key}ChargeApplied`] !== undefined) {
        quoteValues[`${key}ChargeApplied`] = this.state[`${key}ChargeApplied`];
        if (this.state[`${key}ChargeApplied`] && feeAmount) {
          totalFees += feeAmount;
          quoteValues.appliedFees[key] = feeAmount;
        }
      } else {
        totalFees += feeAmount;
        quoteValues.appliedFees[key] = feeAmount;
      }
    });
    quoteValues.totalFees = totalFees;
    quoteValues.totalAmount = parseFloat(quoteValues.sellAmount) + totalFees;

    return quoteValues;
  };

  tickTimer = (timeout) => {
    if (!this.utils.isComponentMounted(this)) {
      return;
    }

    if (parseInt(timeout, 10) > 0 && this.utils.isComponentMounted(this)) {
      this.setState({ quoteTimeout: parseInt(timeout, 10) - 1 });
    } else {
      this.switchOffTimer();
      this.onQuoteValuesSet(null);
      this.showQuoteExpiredModal();
    }
  };

  switchOffTimer = () => {
    ticker.stop();
  };

  showQuoteExpiredModal = () => {
    if (this.utils.isComponentMounted(this)) {
      this.setState({ showQuoteExpiredModal: true });
    }
  };

  hideQuoteExpiredModal = () => {
    this.setState({ showQuoteExpiredModal: false });
  };

  /* -------------- Payment form ------------------ */

  isCardTransferEnabled = (sellCurrency) => {
    const cardCurrencies = this.currencyGateway.getCardCurrency();
    return typeof(cardCurrencies.find(c => c.value === sellCurrency)) !== 'undefined';
  };

  calcCardType = (showCardTransfer) => {
    if (!showCardTransfer) {
      return TYPE_TRANSFER;
    }
    return this.state.cardType ? this.state.cardType : TYPE_DEBIT;
  };

  onPaymentSubmit = (d) => {
    this.setState({
      quoteTimerEnabled: false,
      paymentInProgress: true,
    });
    return true;
  };

  onPaymentFail = (d) => {
    this.props.onBookingError();
    this.setState({
      isLoading: false,
      paymentInProgress: false,
    });
  };

  onChangeCardType = (cardType) => {
    this.setState({
      cardType,
      creditCardChargeApplied: cardType === TYPE_CREDIT,
    });
  };

  /* -------------- Small bene form ------------------ */

  onSendOutValuesChange = (smallSendOutValues) => {
    this.setState({
      smallSendOutValues,
    });
    this.props.onSendOutValuesChange(smallSendOutValues);

    if (sessionStorage) {
      sessionStorage.setItem(SENDOUT_STORAGE, JSON.stringify(smallSendOutValues));
    }
  };

  /* -------------- Footer ------------------ */

  onReviewNextButtonClick = () => {
    this.setState({
      quoteReviewed: true,
    });
  };

  onStartOver = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.utils.scrollScreenTo(0);
    this.setState({
      quoteValues: null,
      quoteReviewed: false,
      quoteTimerEnabled: false,
      paymentInProgress: false,
    });
  };

  onReview = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.utils.scrollScreenTo(0);
    this.setState({
      quoteReviewed: false,
    });
  };

  onSubmit = () => {
    this.ga.logEvent('Trade', 'Booking', { label: this.state.cardType });

    if (this.state.cardType === TYPE_TRANSFER) {
      this.setBookingState();
    }
  };

  setBookingState = (d) => {
    this.setState({
      paymentInProgress: false,
      isLoading: true,
    });

    this.props.onStartBooking(Object.assign({}, this.state.quoteValues));
    this.utils.scrollScreenTo(0);

    this.makeTransferRequest();
  };

  makeTransferRequest = () => {
    const dateStart = new Date();
    const tsStart = dateStart.getTime();
    const params = Object.assign({},
      {
        buyCurrency: this.state.quoteValues.buyCurrency,
        sellCurrency: this.state.quoteValues.sellCurrency,
      });

    this.makeTransferGateway
    .post(params)
    .then(response => {
      if (this.props.onStepComplete) {
        this.props.onStepComplete(response);
      }

      this.ga.logEvent('Trade', 'Booked', {
        label: this.state.cardType,
        value: `${params.sellCurrency}${params.buyCurrency}`,
      });

      const dateEnd = new Date();
      const timeTakenForSubmission = (dateEnd.getTime() - tsStart) / 1000;
      this.ga.logEvent('Trade', 'Metric', {
        label: 'BookDuration',
        value: timeTakenForSubmission,
      });
    })
    .catch(failResponse => {
      this.props.onBookingError();
      if (this.logger.handlePromiseCatch(failResponse)) {
        this.ga.logEvent('Trade', 'BookError', {
          label: 'BACK_END_ERROR',
          value: `${failResponse.message || 'Unknown error'}`,
        });
        return;
      }

      this.ga.logEvent('Trade', 'BookFailed', {
        label: 'UNHANDLED_ERROR',
        value: `${failResponse.message || 'Unknown error'}`,
      });

      const errorMessage = Object.keys(failResponse).reduce((errMsg, key) => {
        if (failResponse[key].message) {
          // eslint-disable-next-line no-param-reassign
          errMsg = errMsg + failResponse[key].message;
        }
        if (failResponse[key].userReference) {
          // eslint-disable-next-line max-len, no-param-reassign
          errMsg = `${errMsg}<br>Please use this reference number to report about it: <b>${failResponse[key].userReference}</b>`;
        }
        return errMsg;
      }, failResponse.message || '');

      if (this.context.showErrorModal) {
        this.context.showErrorModal('Please try again', errorMessage);
      }

      this.setState({
        isLoading: false,
      });
    });
  };

  renderHeader() {
    const {quoteValues, quoteReviewed} = this.state;
    return (
      <div className="quote-steps text-center">
        <h1>{!quoteValues
          ? 'Make a transfer'
          : !quoteReviewed ? 'Review' : 'Confirmation'
        }</h1>

        <div className="circles">
          <hr />
          <div className="circle circle-1">
            {quoteValues
                ? <a href={'#'} onClick={this.onStartOver}>
                    <span></span>
                  </a>
                : <span className={'active'}></span>
            }
          </div>

          <div className="circle circle-2">
            {quoteReviewed
              ? <a href={'#'} onClick={this.onReview}>
                  <span></span>
                </a>
              : <span className={`${quoteValues ? ' active' : ''}`}></span>
            }
          </div>

          <div className="circle circle-3">
            <span className={`${quoteValues && quoteReviewed ? ' active' : ''}`}></span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {isLoading, paymentInProgress, quoteValues, quoteReviewed, cardType, showCardTransfer,
      cardStatus, smallSendOutValues, quoteTimeout, quoteTimerEnabled} = this.state;
    return (
      <div className="make-transfer-step make-transfer-step1">
        <div className="clearfix row">
          <div className="col-md-7">
            {this.renderHeader()}

            {!quoteValues
              ? <Quote
                  setQuoteValues={this.onQuoteValuesSet}
                />
              : null
            }

            {quoteValues && !quoteReviewed
              ? <QuoteReview
                  quoteValues={quoteValues}
                  quoteTimerEnabled={quoteTimerEnabled}
                  quoteTimeout={quoteTimeout}
                  bookAllowed={this.acl.isAllowed('book')}
                  registerAllowed={this.acl.isAllowed('register')}
                  onRegisterClick={this.onRegisterClick}
                  onDone={this.onReviewNextButtonClick}
                  onBack={this.onStartOver}
                  onSendOutValuesChange={this.onSendOutValuesChange}
                  cardType={cardType}
                />
              : null
            }

            {quoteValues && quoteReviewed
              ? <QuoteConfirmation
                  quoteValues={quoteValues}
                  applyCreditCardCharge={cardType === TYPE_CREDIT}
                  quoteTimerEnabled={quoteTimerEnabled}
                  quoteTimeout={quoteTimeout}
                  bookAllowed={this.acl.isAllowed('book')}
                  onDone={this.onSubmit}
                  onStartOver={this.onStartOver}
                  onChangeCardType={this.onChangeCardType}
                  cardType={cardType}
                  showCardTransfer={showCardTransfer}
                  smallSendOutValues={smallSendOutValues}
                  onPaymentSubmit={this.onPaymentSubmit}
                  onPaymentFail={this.onPaymentFail}
                  cardStatus={cardStatus}
                />
              : null
            }

            <ModalQuoteExpired
              showModal={this.state.showQuoteExpiredModal}
              hideModalHandler={this.hideQuoteExpiredModal}
            />

            {(isLoading || paymentInProgress) ? <Loader /> : null}
          </div>

          <div className="col-md-5">
            <QuotesInProgress />
          </div>
        </div>

      </div>
    );
  }
}
