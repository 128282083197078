import Config from 'Config';
import classNames from 'classnames';
import React from 'react';
import { Modal } from 'react-bootstrap';
import serviceLocator from '../../base/service/service-locator';
import CallUs from './../../base/components/call-us';
import FormattedCurrency from './../../base/components/formatted-currency';

export default class ModalOpenDedicatedAccount extends React.Component {

  static propTypes = {
    amount: React.PropTypes.string.isRequired,
    currency: React.PropTypes.oneOfType([
      React.PropTypes.object.isRequired,
      React.PropTypes.string.isRequired,
    ]),
    onClose: React.PropTypes.func.isRequired,
    showModal: React.PropTypes.bool,
  };

  state = {
    registeredClient: false,
  };

  componentWillMount() {
    if (!this.getUserDetails().name) {
      this.auth.onCurrentUserLoaded(this.getUserDetails);
    }
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.showModal !== this.props.showModal);
  }

  /**
   * @returns {boolean}
   */
  onRegisterClick = (event) => {
    event.preventDefault();
    window.location.href = Config.registrationLink;
  };

  getUserDetails = () => {
    const user = this.auth.getCurrentUser();
    if (user.clientId) {
      this.setState({
        registeredClient: true,
      });
    }
    return user;
  };

  auth = serviceLocator.get('Auth');
  utils = serviceLocator.get('Utils');

  renderBody() {
    if (this.state.registeredClient) {
      return (<h4 className="modal-title">
        To provide you with the best rates and services,<br />
        contact your personal broker<br />
        {/* eslint-disable max-len */}
        for trades above <FormattedCurrency value={this.props.amount} currency={this.props.currency} /><br /><br />
        {/* eslint-enable max-len */}
        <CallUs />
      </h4>);
    }

    return (
      <h4 className="modal-title">
        To provide you with the best rates and service,<br />
        {/* eslint-disable max-len */}
        for trades above <FormattedCurrency value={this.props.amount} currency={this.props.currency} /><br /><br />
        {/* eslint-enable max-len */}
        <CallUs message="Call us now on" />
      </h4>);
  }

  render() {
    const btnClassNames = classNames({
      'btn btn-primary': this.state.registeredClient,
    }, 'btn-modal-close');
    return (
      <Modal
        className="modal-alert-10k"
        id="modalAlert10k"
        show={this.props.showModal}
      >
        <Modal.Body>
          <div className="icon-wrap modal-icon-call-wrap">
            <div className="icon-call"></div>
          </div>
          {this.renderBody()}
        </Modal.Body>

        <Modal.Footer>
          <br /><br />
          <a
            href="#"
            className={btnClassNames}
            onClick={this.props.onClose}
          >Return to Platform</a>
        </Modal.Footer>
      </Modal>);
  }
}
