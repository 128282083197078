import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import serviceLocator from './../../base/service/service-locator';
import Config from 'Config';
import UpdatableComponent from '../../base/components/updatable-component';
import ModalErrorMessage from './modal-error-message';
import AppModalLogin from './modal-login';
import SessionCountdown from './session-countdown';
import Content from './content';
import CaptchaModal from './../../captcha/components/captcha-modal';
import DeviceVerificationModal from "../../user/components/device-verification-modal";
import CustomEvent from "custom-event";
import {LOGOUT_ACTION_DEFAULT, LOGOUT_ACTION_TIMEOUT} from "../../user/models/auth";
import Intercom from './Intercom'
import WebFont from 'webfontloader'

require('./../styles/module.less');
WebFont.load({
  google: {
    families: ['Montserrat:300,400,700', 'sans-serif'],
  },
})

const LOGOUT_TIMEOUT = Config.timeoutLogout; // sec

export default class App extends UpdatableComponent {

  routeConfig = serviceLocator.get('RouteConfig');
  auth = serviceLocator.get('Auth');
  aclService = serviceLocator.get('AclService');
  logger = serviceLocator.get('Logger');
  phoneVerificationModel = serviceLocator.get('PhoneVerificationModel');
  sessionLength = LOGOUT_TIMEOUT * 1000;
  verificationInProgress = false;

  static childContextTypes = {
    showErrorModal: React.PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      aclReady: this.aclService.isReady(),
      isAuthorized: this.auth.isAuthorized(),
      showTimer: false,
      sessionEndTime: 0,
      captchaRequired: false,
      verificationRequired: this.showDeviceVerification(),
      showLogin: this.showLogin(),
      showContent: this.showContent(),
      error: {
        title: '',
        message: '',
        reload: false,
        show: false,
      },
      skipConfirm: false,
      secondFactor: 'sms',
    };
    this.logger.setApp(this);

    this.clickHandler = this.clickHandler.bind(this);
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
    this.logout = this.logout.bind(this);
    this.updateLastActiveTime = this.updateLastActiveTime.bind(this);

    this.auth.addListener('onAuthorize', this.onAuthorizedHandler);
    this.auth.jwt.addOnJwtSet(this.onJwtSet);
    document.addEventListener('onAclReady', this.onAclReadyHandler);
    document.addEventListener('onCaptchaRequired', this.onCaptchaRequired);
    document.addEventListener('onDeviceVerificationRequired', this.onDeviceVerificationRequired);
    document.addEventListener('onTwoFactorRequired', this.onTwoFactorRequired);
  }

  componentDidMount() {
    this.updateLastActiveTime();
  }

  /**
   * DON'T USE this function in your code (system React function)
   *
   * @deprecated DON'T USE this function in your code
   * @returns {Object}
   */
  getChildContext() {
    return {
      showErrorModal: this.showErrorModal,
    };
  }

  onAuthorizedHandler = () => {
    this.setState({
      isAuthorized: this.auth.isAuthorized(),
    });
  };

  onJwtSet = () => {
    if (this.state.aclReady && this.state.showLogin === false && this.showLogin()) {
      this.setState({
        showLogin: true,
      });
      this.updateLastActiveTime();
    }
  };

  onAclReadyHandler = () => {
    this.setState({
      aclReady: true,
      verificationRequired: this.showDeviceVerification(),
      showContent: this.showContent(),
      showLogin: this.showLogin(),
    });
    this.updateLastActiveTime();
  };

  logout(action = LOGOUT_ACTION_DEFAULT) {
    this.auth.logout(action);
  }

  updateLastActiveTime() {
    if (this._timeoutLogout) {
      clearTimeout(this._timeoutLogout);
    }
    if (!this.auth.isAuthorized()) {
      return;
    }

    this._timeoutLogout = setTimeout(() => {
      this.logout(LOGOUT_ACTION_TIMEOUT);
    }, this.sessionLength);

    this.bindTimeoutNotify();
  }

  bindTimeoutNotify() {
    if (this._timeoutNotify) {
      clearInterval(this._timeoutNotify);
    }
    if (!this.auth.isAuthorized()) {
      return;
    }

    if (this.state.showTimer) {
      this.setState({
        showTimer: false,
        sessionEndTime: 0,
      });
    }

    const sessionStartTime = Date.now();

    this._timeoutNotify = setInterval(() => {
      const time = Date.now();
      const endTime = Math.max(0,
        Math.round((sessionStartTime + this.sessionLength - time) / 1000));

      if (endTime <= Config.countdownTimerShow) {
        this.setState({
          showTimer: true,
          sessionEndTime: endTime,
        });
      } else if (endTime - 2 <= Config.countdownTimerShow) {
        this.setState({ sessionEndTime: endTime });
      }
    }, 1000);
  }

  clickHandler() {
    this.updateLastActiveTime();
    this.setState({ showTimer: false });
  }

  mouseMoveHandler() {
    if (this.state.showTimer) {
      return;
    }

    this.updateLastActiveTime();
  }

  showLogin() {
    return !this.showDeviceVerification() && !this.showContent() && this.auth.jwt.isTokenValid();
  }

  showContent() {
    const isAuthorizedUser = this.aclService.isReady() && this.auth.isAuthorized();
    return !this.showDeviceVerification() && (this.isGuestPage() || isAuthorizedUser);
  }

  showDeviceVerification() {
    return this.verificationInProgress;
  }

  isGuestPage() {
    const currentRoutePath = this.routeConfig.getCurrentRoutePath();
    const guestRoutes = Config.acl.routes.guest;

    return currentRoutePath !== '' && guestRoutes.indexOf(currentRoutePath) !== -1;
  }

  onErrorModalClose = () => {
    const reload = this.state.error && this.state.error.reload || false;
    const error = Object.assign({}, this.state.error);

    error.show = false;
    this.setState({
      error,
    });

    if (reload) {
      document.location = document.location;
    }
  };

  onCaptchaVerified = () => {
    this.setState({
      captchaRequired: false,
    });
  };

  onCaptchaRequired = () => {
    this.setState({
      captchaRequired: true,
    });
  };

  onDeviceVerified = () => {
    this.verificationInProgress = false;
    this.setState({
      verificationRequired: this.showDeviceVerification(),
      showContent: this.showContent(),
      showLogin: this.showLogin(),
      skipConfirm: false,
    });
    this.updateLastActiveTime();

    const event = new CustomEvent('onDeviceVerified');
    document.dispatchEvent(event);
  };

  onDeviceVerificationRequired = () => {
    this.verificationInProgress = true;
    this.setState({
      verificationRequired: true,
      skipConfirm: false,
    });
  };

  onTwoFactorRequired = ({ detail: { reference, secondFactor } }) => {
    this.verificationInProgress = true;
    if (secondFactor === 'sms') {
      this.phoneVerificationModel.setReference(reference);
    }
    this.setState({
      verificationRequired: true,
      skipConfirm: true,
      secondFactor,
    });
  };

  showErrorModal = (title, message, reload = false) => {
    this.setState({
      error: {
        title: title || '',
        message: message || '',
        reload,
        show: true,
      },
    });
  };

  render() {
    const { error, showContent, showTimer, sessionEndTime, showLogin, isAuthorized, captchaRequired,
      verificationRequired, skipConfirm, secondFactor } = this.state;

    return (
      <div>
        <ModalErrorMessage
          show={error.show}
          title={error.title}
          message={error.message}
          id="app-error"
          onCloseHandler={this.onErrorModalClose}
        />

        {showContent
          ? <Content
            clickHandler={this.clickHandler}
            mouseMoveHandler={this.mouseMoveHandler}
            logout={this.logout}
            isAuthorized={isAuthorized}
            children={this.props.children}
          />
          : null
        }

        <ReactCSSTransitionGroup
          transitionName="timer"
          transitionEnterTimeout={Config.transitionTimeout.opacity}
          transitionLeaveTimeout={Config.transitionTimeout.opacity}
        >
          {(showContent && showTimer)
            ? <SessionCountdown
              sessionEndTime={sessionEndTime}
              updateLastActiveTime={this.updateLastActiveTime}
            />
            : null
          }
        </ReactCSSTransitionGroup>

        {showLogin
          ? <AppModalLogin location={this.props.location} />
          : null
        }

        {captchaRequired
          ? <CaptchaModal onVerify={this.onCaptchaVerified} />
          : null
        }

        {verificationRequired
          ? <DeviceVerificationModal
            onVerify={this.onDeviceVerified}
            skipConfirm={skipConfirm}
            secondFactor={secondFactor}
          />
          : null
        }

        <Intercom enabled={Config.intercom.enabled} id={Config.intercom.id} />
      </div>);
  }
}
