import React from "react";
import Config from "Config";

const AppFooter = () => {
	const { origin } = Config.website;
	return (
		<footer className="footer">
			<div className="container">
				<div className="row">
					<div className="col-sm-8 text-sm-center footer-brand-container">
						<a href="/" className="footer-brand">
							CURRENCY SOLUTIONS
						</a>
					</div>

					<div className="col-sm-4">
						<div className="ficons">
							<a
								href="https://twitter.com/CSolutionsFX"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="ficon twitter"></div>
							</a>
							<a
								href="https://www.instagram.com/currencysolutions/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="ficon instagram"></div>
							</a>
							<a
								href="https://www.linkedin.com/company/99806/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="ficon linkedin"></div>
							</a>
						</div>

						<div className="address">
							Camera Press Building, 21 Queen Elizabeth St, London SE1 2PD, United Kingdom
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-7">
						<div className="footer-links">
							<a href={`${origin}/regulatory`} target="_blank">
								Regulatory
							</a>
							<a href={`${origin}/privacy-policy`} target="_blank">
								Privacy Policy
							</a>
							<a href={`${origin}/cookies-policy`} target="_blank">
								Cookies policy
							</a>
							<a href={`${origin}/terms-and-conditions-personal`} target="_blank">
								Terms & Conditions
							</a>
						</div>
					</div>
					<div className="col-sm-4 col-sm-offset-1">
						<div className="footer-copyright">
							&copy;2019 Currency Solutions Ltd, all rights reserved.
						</div>
					</div>
				</div>
			</div>

			<div className="footer-disclaimer">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<p>
								Currency Solutions Ltd is registered in England, Company No. 04864491.
								Authorised and regulated by the Financial Conduct Authority as a Payment
								Institution, (FRN 512130) and as an Investment Firm, (FRN 602082).
								Registered office: Camera Press Building, 21 Queen Elizabeth St, London
								SE1 2PD, United Kingdom
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default AppFooter;
