import "core-js/stable";
import React from 'react';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from '../../base/service/service-locator';
import Tooltip from './../../app/components/tooltip';
import FormMessage from './../../app/components/form-message';
import FormComponent from './../../base/components/form-component';
import FormTextField from './../../base/components/form-text-field';
import FormAmountField from './../../base/components/form-amount-field';
import SelectCurrency,
{ schema as selectCurrencySchema } from './../../base/form/fields/select-currency';
import ModalOpenDedicatedAccount from './modal-open-dedicated-account';
import ModalCallUs from './../../app/components/modal-call-us';
import ModalEnquiry from './modal-enquiry.js';
import WarningOpenTrade from './warning-open-trade';
import { browserHistory } from 'react-router';
import Loader from './../../app/components/loader';

export default class Quote extends FormComponent {
  static propTypes = {
    setQuoteValues: React.PropTypes.func.isRequired,
  };

  static contextTypes = {
    showErrorModal: React.PropTypes.func,
  };

  currencyGateway = serviceLocator.get('CurrencyGateway');
  getQuoteGateway = serviceLocator.get('GetQuoteGateway');
  tradeGatewayHasOpenTrade = serviceLocator.get('TradeGatewayHasOpenTrade');
  makeEnquiryGateway = serviceLocator.get('MakeEnquiryGateway');
  utils = serviceLocator.get('Utils');
  utilsCurrency = serviceLocator.get('UtilsCurrency');
  ga = serviceLocator.get('GA');
  jwt = serviceLocator.get('Jwt');
  auth = serviceLocator.get('Auth');

  constructor(props) {
    const state = {
      currencyList: [
        { value: 'GBP', label: 'GBP' },
        { value: 'EUR', label: 'EUR' },
      ],
      sellCurrencyList: [

      ],
      showOpenTradeWarning: false,
      currenciesLoaded: false,
      submitDisabled: false,
      showOpenDedicatedAccountModal: false,
      maxAmount: '',
      maxAmountCurrency: null,
      showCallUsModal: false,
      crossBorderChargeApplied: true,
      showEnquiryModal: false,
      showEnquiryModalReason: '',
      value: {
        sellCurrency: { label: 'GBP', value: 'GBP' },
        buyCurrency: { label: 'EUR', value: 'EUR' },
      },
      formValues: null,
      reviewRequired: true,
      loading: false,
      user: null,
    };

    const schema = {
      sellCurrency: selectCurrencySchema.default({ label: 'GBP', value: 'GBP' }),
      sellAmount: yup.string(),
      buyCurrency: selectCurrencySchema.default({ label: 'EUR', value: 'EUR' }),
      buyAmount: yup.string(),
    };

    super(props, state, schema);

    this.hideOpenDedicatedAccountModal = this.hideOpenDedicatedAccountModal.bind(this);
    this.hideCallUsModal = this.hideCallUsModal.bind(this);
    this.hideEnquiryModal = this.hideEnquiryModal.bind(this);
  }

  componentDidMount() {
    this.loadData();

    if (this.auth.isUserDetailsLoaded()) {
      this.onCurrentUserLoaded();
    } else {
      this.auth.addListener('onCurrentUserLoaded', () => { this.onCurrentUserLoaded(); });
    }
  }


  onCurrentUserLoaded() {
    this.setState({
      user: this.auth.getCurrentUser(),
    });
  }

  loadData() {
    this.currencyGateway
      .getList(currencyList => {
        const sortedCurrencyList =
          this.utilsCurrency.currenciesToTop(['GBP', 'EUR', 'USD'], currencyList);

        this.cardCurrency = this.currencyGateway.getCardCurrency();
        this.buyCurrency = this.currencyGateway.getBuyCurrency();
        this.sellCurrency = this.currencyGateway.getSellCurrency();
        const sellCurrencyList = this.sellCurrency.concat({ value: 'OTHER', label: 'Other' });
        const sortedSellCurrencyList =
          this.utilsCurrency.currenciesToTop(['GBP', 'EUR', 'USD'], sellCurrencyList);
        this.setState({
          currencyList: sortedCurrencyList,
          currenciesLoaded: true,
          sellCurrencyList: sortedSellCurrencyList,
        }, () => {
          const payload = this.jwt.getPayload();
          if (payload && payload.user_data && payload.user_data.enquiry) {
            const sellCurrency = sortedCurrencyList.reduce((out, curr) => {
              if (curr.value == payload.user_data.enquiry.sell) {
                return curr;
              }
              return out;
            }, {});
            const buyCurrency = sortedCurrencyList.reduce((out, curr) => {
              if (curr.value == payload.user_data.enquiry.buy) {
                return curr;
              }
              return out;
            }, {});

            const values = {
              sellCurrency,
              sellAmount: `${payload.user_data.enquiry.amount}`,
              buyCurrency,
              buyAmount: '',
            };
            this.setFieldsValues(values, this.formOnSubmit);
          }
        });
      });
  }

  formOnSubmit = (fields = this.state.value) => {
    const formattedFields = Object.assign({}, fields);

    ['sellAmount', 'buyAmount'].forEach(key => {
      if (key in formattedFields) {
        formattedFields[key] = this.utils.formatAmount(formattedFields[key]).value;
      }
    });

    const currencyPair = `${formattedFields.sellCurrency
      .value}/${formattedFields.buyCurrency.value}`;
    this.ga.logEvent('Trade', 'Quoting', {
      label: `${(formattedFields.buyAmount ? 'B' : 'S')}:${currencyPair}`,
    });

    this._formOnSubmit(formattedFields);

    if (this.isGetQuoteAvailable(formattedFields.sellCurrency, formattedFields.buyCurrency)) {
      this.disableQuote(true, true);
      this.getQuote(formattedFields);
    } else {
      this.showEnquiryModal('Unavailable Currency');
    }
  };

  formOnChange = (value, updatedPath, onValueUpdateHandler = null) => {
    const updatedFields = Array.isArray(updatedPath) ? updatedPath : [];
    const formattedValue = Object.assign({}, value);

    // Prepare handler which will run after setState of value
    const afterValueSetHandler = () => {
      // Run chained onValueUpdateHandler
      if (onValueUpdateHandler) {
        onValueUpdateHandler();
      }

      const updatedAmountField = updatedFields.find(el => el === 'sellAmount' || el === 'buyAmount');

      if (value.buyAmount) {
        this.setState({
          showOpenDedicatedAccountModal: false,
        });
      }

      if (updatedAmountField) {
        const val = Object.assign({}, this.state.value);
        const formErrors = Object.assign({}, this.state.formErrors);
        const nextState = {};

        let resetField;
        if (
          updatedAmountField === 'sellAmount' &&
          (this.state.value.buyAmount === '' || this.state.value.buyAmount)
        ) {
          resetField = 'buyAmount';
        } else if (
          updatedAmountField === 'buyAmount' &&
          (this.state.value.sellAmount === '' || this.state.value.sellAmount)
        ) {
          resetField = 'sellAmount';
        }
        if (resetField) {
          delete val[resetField];
          delete formErrors[resetField];
          nextState.value = val;
          nextState.formErrors = formErrors;
        }

        nextState.isAmountEmpty =
          (this.state.value.sellAmount === '' || !this.state.value.sellAmount) &&
          (this.state.value.buyAmount === '' || !this.state.value.buyAmount);

        this.setState(nextState);
      }
    };

    if (
      updatedFields.includes('sellCurrency') &&
      this.state.value.buyCurrency.value === formattedValue.sellCurrency.value
    ) {
      formattedValue.buyCurrency = this.state.value.sellCurrency;
    }
    if (
      updatedFields.includes('buyCurrency') &&
      this.state.value.sellCurrency.value === formattedValue.buyCurrency.value
    ) {
      formattedValue.sellCurrency = this.state.value.buyCurrency;
    }

    this._formOnChange(formattedValue, updatedFields, afterValueSetHandler);
  };

  isGetQuoteAvailable(sellCurrency, buyCurrency) {
    return this.sellCurrency && this.sellCurrency.find(c => c.value === sellCurrency) &&
      this.buyCurrency.find(c => c.value === buyCurrency);
  }

  requestQuote = () => {
    const dateStart = new Date();
    const tsStart = dateStart.getTime();

    const fields = this.formValues;
    this.setState({ showOpenTradeWarning: false, reviewRequired: false, loading: true });

    this.getQuoteGateway
      .post(fields)
      .then(response => {
        if (!this.utils.isComponentMounted(this)) {
          return;
        }

        const currencyPair = `${response.sellCurrency}/${response.buyCurrency}`;
        this.ga.logEvent('Trade', 'Quoted', {
          label: `${(fields.buyAmount ? 'B' : 'S')}:${currencyPair}`,
          value: response.gbpValue,
        });

        const dateEnd = new Date();
        const timeTakenForSubmission = (dateEnd.getTime() - tsStart) / 1000;
        this.ga.logEvent('Trade', 'Metric', {
          label: 'QuoteDuration',
          value: timeTakenForSubmission,
        });

        const quoteValues = Object.assign({}, fields, response);
        this.setState({
          formErrors: {},
          loading: false,
        });

        this.props.setQuoteValues(quoteValues);
      })
      .catch(failResponse => {
        this.setState({ loading: false });
        this.disableQuote();
        if (this.logger.handlePromiseCatch(failResponse)) {
          this.ga.logEvent('Trade', 'QuoteError', {
            label: 'BACK_END_ERROR',
            value: `${failResponse.message || 'Unknown error'}`,
          });
          return;
        }
        let proceedFurther = true;
        Object.keys(failResponse).forEach(key => {
          if (failResponse[key].code) {
            if (failResponse[key].code === 'AMOUNT_IS_OUT_OF_BAND') {
              const { name, email, phoneCode, phoneNumber } = this.state.user;
              const valuesToSend = {
                sell: fields.sellCurrency,
                buy: fields.buyCurrency,
                amount: fields.sellAmount || fields.buyAmount,
                action: fields.sellAmount ? 'sell' : 'buy',
                name: `${name} (Amount too big)`,
                email,
                phoneCode,
                telephone: phoneNumber,
              };
              this.makeEnquiryGateway.post(valuesToSend, true);

              const maxAmountCurrency = fields.buyAmount ? fields.buyCurrency : fields.sellCurrency;
              this.setState({
                showOpenDedicatedAccountModal: true,
                maxAmount: failResponse[key].message,
                maxAmountCurrency: maxAmountCurrency.value || maxAmountCurrency,
              });

              this.ga.logEvent('Trade', 'QuoteFailed', {
                label: 'AMOUNT_IS_OUT_OF_BAND',
              });
            }

            if (failResponse[key].code === 'INVALID_CURRENCY_ERROR') {
              this.setState({
                showCallUsModal: true,
              });
            }

            if (failResponse[key].code === 'RATE_IS_NOT_AVAILABLE') {
              this.showEnquiryModal(fields.sellCurrency === 'OTHER'
                ? 'Other Currency' : 'Rate is not available');

              this.ga.logEvent('Trade', 'QuoteFailed', {
                label: 'RATE_IS_NOT_AVAILABLE',
                value: `${fields.sellCurrency}${fields.buyCurrency}`,
              });
            }

            if (failResponse[key].code === 'AMOUNT_IS_BELOW_LOWER_EDGE_OF_BAND' &&
              this.context.showErrorModal
            ) {
              this.context.showErrorModal(
                'Please get quote for bigger amount',
                failResponse[key].message
              );
            }

            proceedFurther = false;
          }
        });
        this.disableQuote();
        if (proceedFurther) {
          this._onFailResponse(failResponse);

          this.ga.logEvent('Trade', 'QuoteFailed', {
            label: 'UNHANDLED_ERROR',
            value: `${failResponse.message || 'Unknown error'}`,
          });
        }
      });
  };

  getQuote(fields) {
    this.setState({ loading: true });

    this.formValues = fields;

    if (!this.state.reviewRequired) {
      this.requestQuote(fields);
      return;
    }

    this.tradeGatewayHasOpenTrade
      .get()
      .then(data => {
        if (!data.has) {
          this.requestQuote(fields);
        } else {
          this.setState({ showOpenTradeWarning: true, loading: false });
        }
      });
  }

  disableQuote(isSubmitDisabled = false, isQuoteLoading = false) {
    if (!this.utils.isComponentMounted(this)) {
      return;
    }

    this.setState({
      submitDisabled: isSubmitDisabled,
    });
    this.props.setQuoteValues(null);
  }

  hideOpenDedicatedAccountModal() {
    this.disableQuote();
    this.setState({ showOpenDedicatedAccountModal: false });
  }

  hideCallUsModal() {
    this.setState({ showCallUsModal: false });
  }

  showEnquiryModal(reason) {
    this.setState({ showEnquiryModalReason: reason, showEnquiryModal: true });
  }

  hideEnquiryModal() {
    this.setState({ showEnquiryModal: false });
  }

  renderForm() {
    let tooltipInSellCurrency =
      this.state.value.sellCurrency.value || this.state.value.sellCurrency;
    if (tooltipInSellCurrency !== 'OTHER') {
      tooltipInSellCurrency = `in <strong>${tooltipInSellCurrency}</strong>`;
    } else {
      tooltipInSellCurrency = '';
    }

    let tooltipInBuyCurrency =
      this.state.value.buyCurrency.value || this.state.value.buyCurrency;
    if (tooltipInBuyCurrency !== 'OTHER') {
      tooltipInBuyCurrency = `in <strong>${tooltipInBuyCurrency}</strong>`;
    } else {
      tooltipInBuyCurrency = '';
    }

    return (
      <Form
        ref="form"
        component="div"
        schema={this.schema}
        value={this.state.value}
        errors={this.state.formErrors}
        onError={this.formOnError}
        onChange={this.formOnChange}
        onSubmit={this.formOnSubmit}
        className="form make-transfer-quote form-horizontal clearfix"
      >
        <div className="col-md-8 col-md-offset-2">
          <div className="make-transfer-quote-wrap">
            <div className="form-group form-group-select-currency">
              <div>
                <SelectCurrency
                  id="sellCurrency"
                  name="sellCurrency"
                  options={this.state.sellCurrencyList}
                  tooltip={false}
                />
                <div className="make-transfer-amount-control-wrap">
                  <FormTextField
                    type={FormAmountField}
                    name="sellAmount"
                    id="sellAmount"
                    className="form-control"
                    placeholder="Enter amount"
                  />
                  <span className="make-transfer-quote-hint">I have</span>
                </div>
                <FormMessage htmlFor="sellAmount" />
              </div>

              <div className="hidden-xs col-sm-3 make-transfer-quote-tooltip-wrap">
                {/* eslint-disable max-len */}
                <Tooltip text={`Specify the amount ${tooltipInSellCurrency} you wish to send`} />
                {/* eslint-enable max-len */}
              </div>
            </div>

            <div className="form-group form-group-select-currency">
              <div>
                <SelectCurrency
                  id="buyCurrency"
                  name="buyCurrency"
                  options={this.state.currencyList}
                  tooltip={false}
                />
                <div className="make-transfer-amount-control-wrap">
                  <FormTextField
                    type={FormAmountField}
                    name="buyAmount"
                    id="buyAmount"
                    className="form-control"
                    placeholder="Enter amount"
                  />
                  <span className="make-transfer-quote-hint">I want</span>
                </div>
                <FormMessage htmlFor="buyAmount" />
              </div>

              <div className="hidden-xs col-sm-3 make-transfer-quote-tooltip-wrap">
                <Tooltip text={`Specify the amount ${tooltipInBuyCurrency} you wish to receive`} />
              </div>
            </div>

            {this.state.loading && <Loader />}

            <div className="form-group form-group-btn">
              <div className="">
                <FormMessage htmlFor="common" />

                <Form.Button
                  type="submit"
                  className="btn btn-lg btn-block btn-primary btn-quote-submit"
                  disabled={
                    this.state.submitDisabled ||
                    (!this.state.value.buyAmount && !this.state.value.sellAmount)
                  }
                >Get a Quote</Form.Button>
              </div>
            </div>
          </div>
        </div>
      </Form>);
  }

  render() {
    return (
      <div>
        {this.state.currencyList.length ? this.renderForm() : null}

        <ModalOpenDedicatedAccount
          amount={this.state.maxAmount}
          currency={this.state.maxAmountCurrency}
          showModal={this.state.showOpenDedicatedAccountModal}
          onClose={this.hideOpenDedicatedAccountModal}
        />

        <WarningOpenTrade
          showModal={this.state.showOpenTradeWarning}
          onClose={this.requestQuote}
          onReview={() => browserHistory.replace('/transfer-history')}
        />

        <ModalCallUs
          showModal={this.state.showCallUsModal}
          title="Unfortunately we can't make transfer for this currency pair online."
          subtitle="To get a free quote please call us"
          onClose={this.hideCallUsModal}
        />

        <ModalEnquiry
          showModal={this.state.showEnquiryModal}
          reason={this.state.showEnquiryModalReason}
          hideModalHandler={this.hideEnquiryModal}
          sellCurrency={this.state.value.sellCurrency.value}
          buyCurrency={this.state.value.buyCurrency.value}
          buyAmount={this.state.value.buyAmount}
          sellAmount={this.state.value.sellAmount}
        />
      </div>);
  }
}
