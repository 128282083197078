import "core-js/stable";
import React from 'react';
import yup from 'yup';
import classNames from 'classnames';
import serviceLocator from '../../base/service/service-locator';
import UpdatableComponent from './../../base/components/updatable-component';
import SelectPhoneCode,
  { schema as phoneCodeSchema } from './../../base/form/fields/select-phone-code';
import FormTextField from './../../base/components/form-text-field';
import FormTextFieldWithTooltip from './../../base/components/form-text-field-with-tooltip';
import PhoneVerificationModal from './phone-verification';

export const schema = {
  mobileReference: yup.string(),
  phoneCode: phoneCodeSchema,
  mobile: yup.string().matches(/^\+?[\d*]*$/, 'Only numbers allowed')
    .max(11),
};

export default class PhoneVerifyFormGroup extends UpdatableComponent {

  phoneVerificationModel = serviceLocator.get('PhoneVerificationModel');
  jwt = serviceLocator.get('Jwt');
  auth = serviceLocator.get('Auth');
  utils = serviceLocator.get('Utils');
  countryGateway = serviceLocator.get('CountryGateway');

  static contextTypes = {
    formValue: React.PropTypes.object,
    setFieldsValues: React.PropTypes.func,
    setFieldError: React.PropTypes.func,
    setFieldSuccess: React.PropTypes.func,
  };

  static propTypes = {
    label: React.PropTypes.string,
    verifiable: React.PropTypes.bool,
    verifyLabel: React.PropTypes.string,
    verifiedLabel: React.PropTypes.string,
    modalTitle: React.PropTypes.string,
    tooltip: React.PropTypes.bool,
    disabled: React.PropTypes.bool,
    phoneCode: React.PropTypes.string,
    phoneNumber: React.PropTypes.string,
    codeFieldName: React.PropTypes.string,
    numberFieldName: React.PropTypes.string,
    userId: React.PropTypes.string,
    verifyUser: React.PropTypes.bool,
    setUserVerificationDone: React.PropTypes.func,
    skipConfirm: React.PropTypes.bool,
    reVerify: React.PropTypes.bool,
  };

  static defaultProps = {
    label: 'Mobile',
    verifiable: true,
    disabled: false,
    codeFieldName: 'phoneCode',
    numberFieldName: 'mobile',
    skipConfirm: false,
    reVerify: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      buttonLabel: this.props.verifyLabel,
      phoneVerifyButtonEnabled: false,
      fieldsEnabled: !props.disabled,
      phoneUnverified: false,
      ipLimitExceed: false,
      show: this.props.skipConfirm,
    };
    this.verifiedPhones = [];

    this.close = this.close.bind(this);
  }

  onVerificationSucceed = (data) => {
    const { codeFieldName, numberFieldName } = this.props;

    if (data.result) {
      const phoneNumber = this.context.formValue[numberFieldName];
      const phoneCode = this.context.formValue[codeFieldName].value.toString();
      const mobileReference = this.state.mobileReference;
      this.addVerifiedPhone(phoneCode, phoneNumber, mobileReference);
      this.setReference(mobileReference);

      this.close();

      this.context.setFieldError(numberFieldName, null, () => {
        this.context.setFieldSuccess(numberFieldName, true);
      });

      this.setState({
        phoneVerifyButtonEnabled: false,
        phoneUnverified: false,
        phoneVerified: true,
        buttonLabel: this.props.verifiedLabel,
      });

      if (data.token) {
        this.jwt.set(data.token);
      }

      if (this.props.verifyUser && this.props.setUserVerificationDone) {
        this.props.setUserVerificationDone();
      }
    }
  };

  onVerificationFailed = (error) => {
    this.setState({
      phoneVerifyButtonEnabled: false,
      fieldsEnabled: false,
      phoneUnverified: true,
      phoneVerified: false,
    });

    if (error[0] && error[0].code === 'ATTEMPTS_LIMIT') {
      this.setState({ isVerifyCodeValid: false });
      this.close();
    }
  };

  getPhoneFromUserDetails = (user = null) => {
    if (!user) {
      // eslint-disable-next-line no-param-reassign
      user = this.auth.getCurrentUser();
    }
    if (user.phoneCode && user.phoneNumber) {
      this.countryGateway.getList(() => {
        const codes = this.countryGateway.getCodes();
        const countryCode = Object.keys(codes).find(key => codes[key] === user.phoneCode);
        const phoneCode = {
          value: user.phoneCode,
          label: this.countryGateway.getNameByCountryShortCode(countryCode),
        };
        this.setUserPhone(phoneCode, user.phoneNumber);
      });
    } else if (this.props.verifyUser && this.props.setUserVerificationDone) {
      this.props.setUserVerificationDone();
    }
    return user;
  };

  setUserPhone = (phoneCode, mobile) => {
    const { codeFieldName, numberFieldName } = this.props;
    this.context.setFieldsValues({ [codeFieldName]: phoneCode, [numberFieldName]: mobile });
    this.phoneVerificationModel.setPhoneCode(phoneCode.value);
    this.phoneVerificationModel.setPhoneNumber(mobile);
    this.setState({ fieldsEnabled: false });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const { codeFieldName, numberFieldName } = this.props;
    const { formValue } = this.context;
    if (
      formValue[numberFieldName] !== nextContext.formValue[numberFieldName] ||
      (formValue[codeFieldName] &&
      formValue[codeFieldName].value !== nextContext.formValue[codeFieldName].value)
    ) {
      this.handleFormValue(nextContext.formValue);
    }

    if (this.props.disabled !== nextProps.disabled) {
      this.setState({
        fieldsEnabled: !nextProps.disabled,
      });
    }
  }

  componentWillMount() {
    const { verifyUser, phoneCode, phoneNumber, setUserVerificationDone, reVerify } = this.props;

    // If we want to confirm account owner then get phone from user details
    if (verifyUser) {
      if (this.jwt.isUserVerified() && !reVerify && setUserVerificationDone) {
        setUserVerificationDone();
        return;
      }

      if (!this.auth.isUserDetailsLoaded()) {
        this.auth.onCurrentUserLoaded(this.getPhoneFromUserDetails);
        if (phoneCode && phoneNumber) {
          this.getPhoneFromUserDetails({
            phoneCode: phoneCode,
            phoneNumber: phoneNumber,
          });
        }
      } else {
        this.getPhoneFromUserDetails();
      }
    }
  }

  isAccessDenied(data) {
    if (data[0] && data[0].code === 'ACCESS_DENIED') {
      this.setState({
        ipLimitExceed: true,
        loading: false,
      });
      return true;
    }
    return false;
  }

  handleFormValue(formValue) {
    const { codeFieldName, numberFieldName } = this.props;
    const phoneCode = formValue[codeFieldName] && formValue[codeFieldName].value ?
      formValue[codeFieldName].value.toString() :
      null;
    const phoneNumber = formValue[numberFieldName] ?
      formValue[numberFieldName].toString() :
      null;
    const verifiedPhoneReference = this.isPhoneVerified(phoneCode, phoneNumber);
    if (verifiedPhoneReference) {
      this.setReference(verifiedPhoneReference);
      this.context.setFieldSuccess(numberFieldName, true);
      this.setState({
        buttonLabel: this.props.verifiedLabel,
        phoneVerifyButtonEnabled: false,
        phoneVerified: true,
      });
    } else {
      this.setReference(null);
      this.context.setFieldSuccess(numberFieldName, false);
      this.setState({
        buttonLabel: this.props.verifyLabel,
        phoneVerified: false,
      });
      if (phoneNumber && phoneNumber !== '') {
        yup.object(schema).validate({ [numberFieldName]: phoneNumber })
          .then(() => {
            if (!this.utils.isComponentMounted(this)) {
              return;
            }
            this.setState({
              phoneVerifyButtonEnabled: true,
            });
          })
          .catch(() => {
            if (!this.utils.isComponentMounted(this)) {
              return;
            }
            this.setState({
              phoneVerifyButtonEnabled: false,
            });
          });
      } else {
        this.setState({
          phoneVerifyButtonEnabled: false,
        });
      }
    }
  }

  sendVerificationRequest = () => {
    const { codeFieldName, numberFieldName } = this.props;
    const phoneNumber = this.context.formValue[numberFieldName];
    const phoneCode = this.context.formValue[codeFieldName].value;
    this.phoneVerificationModel.setPhoneNumber(phoneNumber).setPhoneCode(phoneCode);
    if (this.props.userId) {
      this.phoneVerificationModel.setUserId(this.props.userId);
    }

    const verifiedPhoneReference = this.isPhoneVerified(phoneCode, phoneNumber);
    if (verifiedPhoneReference) {
      this.setReference(verifiedPhoneReference);
      this.close();

      this.setState({
        buttonLabel: this.props.verifiedLabel,
        phoneVerifyButtonEnabled: false,
        phoneVerified: true,
      });

      return;
    }

    if (this.phoneVerificationModel.verificationRequestSent) {
      return;
    }

    this.setState({ loading: true });

    this.phoneVerificationModel.verifyRequest()
      .then((data) => {
        if (this.isAccessDenied(data)) {
          return;
        }

        if (data.reference) {
          this.setReference(data.reference);
        }

        if (data.hasOwnProperty('verified')) {
          if (data.hasOwnProperty('phones') && Array.isArray(data.phones)) {
            data.phones.forEach(phone => {
              this.addVerifiedPhone(
                phone.phoneCode,
                phone.mobile,
                phone.reference
              );
            });
          }

          this.close();

          this.setState({
            buttonLabel: this.props.verifiedLabel,
            phoneVerifyButtonEnabled: false,
            fieldsEnabled: false,
            phoneVerified: true,
          });

          return;
        }

        if (data.reference) {
          this.show();
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (this.context.setFieldError && error[0] && error[0].message) {
          this.context.setFieldError(numberFieldName, error[0].message);
        }
      });
  };

  close = () => {
    this.setState({ show: false, loading: false });
  };

  show = () => {
    this.setState({ show: true, loading: false });
  };

  addVerifiedPhone(phoneCode, mobile, reference) {
    const { codeFieldName, numberFieldName } = this.props;
    if (!this.isPhoneVerified(phoneCode, mobile)) {
      this.verifiedPhones.push({ [codeFieldName]: phoneCode, [numberFieldName]: mobile, reference });
    }
  }

  isPhoneVerified(phoneCode, mobile) {
    const { codeFieldName, numberFieldName } = this.props;
    const { verifiedPhones } = this;
    let verifiedReference = null;
    verifiedPhones.forEach(verifiedPhone => {
      if (verifiedPhone[codeFieldName] === phoneCode && verifiedPhone[numberFieldName] === mobile) {
        verifiedReference = verifiedPhone.reference;
      }
    });
    return verifiedReference;
  }

  setReference = (mobileReference) => {
    this.context.setFieldsValues({ mobileReference });
    this.setState({ mobileReference });
  };

  renderButton() {
    const btnClassNames = classNames('btn btn-sm btn-info btn-mobile', {
      'mobile-verified': this.state.phoneVerified,
    });

    return (
      !this.state.loading
      ? <button
        type="button"
        className={btnClassNames}
        onClick={this.sendVerificationRequest}
        disabled={!this.state.phoneVerifyButtonEnabled || this.state.ipLimitExceed || this.props.disabled}
      >{this.state.buttonLabel}</button>
      : null);
  }

  renderPhoneField() {
    const { numberFieldName, label } = this.props;
    return this.props.tooltip
      ? <FormTextFieldWithTooltip
        name={numberFieldName}
        className="form-control input-sm form-control-mobile"
        disabled={!this.state.fieldsEnabled}
        placeholder={label}
        id={`${numberFieldName}Field`}
      />
      : <FormTextField
        name={numberFieldName}
        className="form-control input-sm form-control-mobile"
        disabled={!this.state.fieldsEnabled}
        placeholder={label}
        id={`${numberFieldName}Field`}
      />;
  }

  render() {
    const { verifiable, codeFieldName, numberFieldName, skipConfirm } = this.props;
    const { formValue } = this.context;
    const phoneNumber = formValue[numberFieldName];
    const phoneCode = formValue[codeFieldName] && formValue[codeFieldName].value;


    const inputGroupClassNames = classNames('input-group input-group-sm', {
      'has-success': this.state.phoneVerified,
      'has-error': this.state.phoneUnverified,
    });

    const verificationModal = (
      <PhoneVerificationModal
        modalTitle={this.props.modalTitle}
        onVerificationSucceed={this.onVerificationSucceed}
        onVerificationFailed={this.onVerificationFailed}
        setFieldsValues={this.context.setFieldsValues}
        show={this.state.show}
        onHide={this.close}
        skipConfirm={skipConfirm}
        phoneCode={`${phoneCode}`}
        phoneNumber={`${phoneNumber || ''}`}
      />
    );

    if (skipConfirm) {
      return verifiable ? verificationModal : null;
    }

    return (
      <div>
        <div className={this.state.loading ? 'has-loader' : ''}>
          {verifiable ? this.renderButton() : null}
          <div className={`form-control-mobile-wrap${!verifiable ? ' wide' : ''}`}>
            <div className={inputGroupClassNames}>
              <SelectPhoneCode
                className="input-sm"
                name={codeFieldName}
                label="Country Code"
                placeholder="Country Code"
                disabled={!this.state.fieldsEnabled}
                tooltip={this.props.tooltip}
              />
              <div className="form-group form-group-sm">
                {this.renderPhoneField()}
              </div>
            </div>
          </div>
          {
            this.state.ipLimitExceed
            ? <div>Your ip address has been temporarily blocked. Please try again later</div>
            : null
          }
        </div>
        {verifiable
          ? verificationModal
          : null
        }
      </div>);
  }
}
