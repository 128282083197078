import React from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from './../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import FormGroup from './../../app/components/form-group';
import FormTextField from './../../base/components/form-text-field';
import CallUs from "../../base/components/call-us";

export default class GoogleAuthenticatorModal extends FormComponent {
  static propTypes = {
    onVerificationSucceed: React.PropTypes.func,
  };

  constructor(props) {
    const state = {
      isVerifyCodeValid: true,
      formError: false,
    };
    const schema = {
      code: yup.string().min(6).max(7),
    };

    super(props, state, schema);

    this.googleAuthenticatorModel = serviceLocator.get('GoogleAuthenticatorModel');
    this.ga = serviceLocator.get('GA');
    this.jwt = serviceLocator.get('Jwt');
    this.auth = serviceLocator.get('Auth');
  }

  formOnChange = (value, updatedPaths, onValueUpdateHandler = null) => {
    this._formOnChange(value, updatedPaths, onValueUpdateHandler);

    if (
      updatedPaths.indexOf('code') !== -1 &&
      this.state.formError
    ) {
      this.setState({ formError: false });
    }
  };

  formOnSubmit = (fields) => {
    this._formOnSubmit(fields);
    this.verify(`${fields.code}`.replace(/[^0-9]*/g, ''));
  };

  verify(code) {
    this.googleAuthenticatorModel.post({code})
      .then((data) => {
        if (data.token) {
          this.jwt.set(data.token);
        }

        this.ga.logEvent('SCA', 'Success');
        this.auth.loadUserDetails();

        const {onVerificationSucceed} = this.props;
        onVerificationSucceed();
      })
      .catch((error) => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }

        const msg = error && ((error[0] && error[0].message) || error.message || (error.toString && error.toString()))
          || 'Unknown error';
        this.ga.logEvent('SCA', 'Error', { label: `${msg}` });
        this.setState({ formError: `${msg}` });
      });
  }

  onEntering = () => {
    if (document.querySelector('.wrapper-outer')) {
      document.querySelector('.wrapper-outer').classList.add('blur');
    }

    const input = document.getElementById("code-input");
    input && input.addEventListener("keyup", event => {
      if (event.key === "Enter" || event.keyCode === 13) {
        const el = document.querySelector('button.btn-code-submit');
        if (el) {
          el.click();
        }
      }
    });

    this.ga.logEvent('SCA', 'GoogleAuth');
  };

  onExiting = () => {
    if (document.querySelector('.wrapper-outer')) {
      document.querySelector('.wrapper-outer').classList.remove('blur');
    }

    this.ga.logEvent('SCA', 'Close');
  };


  render() {
    return (
      <Modal
        show
        className="modal fade modal-phone-verification"
        id="modalGoogleAuthenticator"
        onHide={() => {}}
        onEntering={this.onEntering}
        onExiting={this.onExiting}
      >
        <Modal.Header>
          <div className="navbar-brand">CURRENCY SOLUTIONS</div>
        </Modal.Header>

        <Modal.Body>
          <p>
            Please enter Google Authenticator 6-digit verification code
          </p>

          <Form
            ref="form"
            component="div"
            className="form"
            schema={this.schema}
            value={this.state.value}
            errors={this.state.formErrors}
            onError={this.formOnError}
            onChange={this.formOnChange}
            onSubmit={this.formOnSubmit}
          >
            <div className="row">
              <div className="col-sm-4">
                <FormGroup fieldName="codeField">
                  <FormTextField
                    type="text"
                    name="code"
                    className="form-control input"
                    placeholder="XXX XXX"
                    id="code-input"
                  />

                  {this.state.formError
                    ? (<div className="form-group-message alert-danger">
                      <span className="validation-error">{this.state.formError}</span>
                    </div>)
                    : null
                  }
                </FormGroup>
              </div>

              <div className="col-sm-8">
              </div>
            </div>

            <FormGroup fieldName="common">
              <Form.Button type="submit" className="btn btn-sm btn-info btn-code-submit">Continue</Form.Button>
            </FormGroup>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <CallUs
            message={'If you have any issues using Google Authenticator, please call us on'}
            newLine
            left
          />
        </Modal.Footer>
      </Modal>);
  }
}
