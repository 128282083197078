import React from 'react';
import { Modal } from 'react-bootstrap';
import serviceLocator from '../../base/service/service-locator';
import Config from 'Config';
import WorldpayForm from './worldpay-form';
import {TYPE_DEBIT} from "./payment";
import Loader from './../../app/components/loader';

const SUCCESS_PAYMENT_STATUS = 'AUTHORIZED';

export default class CardPaymentForm extends React.Component {

  static propTypes = {
    type: React.PropTypes.string.isRequired,
    amount: React.PropTypes.number.isRequired,
    currency: React.PropTypes.string.isRequired,
    buyCurrency: React.PropTypes.string.isRequired,
    quoteValues: React.PropTypes.object,
  };

  static contextTypes = {
    setPaymentResult: React.PropTypes.func,
    onPaymentFailedResponse: React.PropTypes.func,
  };

  logger = serviceLocator.get('Logger');
  createCardTransactionGateway = serviceLocator.get('CreateCardTransactionGateway');
  jwt = serviceLocator.get('Jwt');
  auth = serviceLocator.get('Auth');
  ga = serviceLocator.get('GA');

  constructor(props) {
    super(props);
    this.state = {
      showPaymentModal: false,
      refId: null,
      cardUrl: null,
    };
  }
  //
  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.refId !== this.submittedRefId &&
  //     this.state.cardUrl &&
  //     document.worldpayIframe
  //   ) {
  //     this.submittedRefId = this.state.refId;
  //     document.worldpayIframe.submit();
  //   }
  // }

  componentWillMount() {
    this.auth.disableAutoLogOut();

    const { quoteValues } = this.props;
    const quoteCurrencies = {
      currency: quoteValues.sellCurrency,
      buyCurrency: quoteValues.buyCurrency,
    };

    this.createCardTransactionGateway
      .post(
        Object.assign({
          data: {
            '3ds': 'ok',
          },
          type: TYPE_DEBIT,
        }, quoteCurrencies)
      )
      .then(response => {
        if (response.data && response.data.url !== '' && response.data.ref_id !== '') {
          // window.addEventListener('message', this.onIframeMessageReceive, false);
          this.setState({
            showPaymentModal: false,
            transactionId: response.id,
            cardUrl: response.data.url,
            refId: response.data.ref_id,
          });
          return;
        }
      })
      .catch(failResp => {
        this.setState({
          showPaymentModal: false,
        });
      });
  }

  componentDidMount() {
    this.ga.logPageView('/transfer-card-payment');
  }

  componentWillUnmount() {
    this.auth.enableAutoLogOut();
  }

  onIframeMessageReceive = (event) => {
    if (!event.origin === Config.webUrl) {
      return;
    }

    if (event.data && event.data.paymentStatus) {
      this.setState({
        showPaymentModal: false,
      });

      window.removeEventListener('message', this.onIframeMessageReceive, false);

      if (event.data.paymentStatus === SUCCESS_PAYMENT_STATUS) {
        this.context.setPaymentResult(event.data);
        return;
      }

      this.context.onPaymentFailedResponse(event.data);
    }
  };

  renderForm() {
    const { cardUrl } = this.state;

    if (!cardUrl) {
      return <Loader />;
    }

    return (
      <WorldpayForm
        url={cardUrl}
      />
    );
  }

  render() {
    return (
      <div className="card-form-wrapper">
        {this.renderForm()}

        <Modal
          show={this.state.showPaymentModal}
          bsSize="large"
          backdrop="static"
          keyboard={false}
          className="modal-card-payment"
        >
          <Modal.Header>
            <Modal.Title>Card Payment Processing</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.state.cardUrl
              ? <form name="worldpayIframe" target="paymentFrame" method="GET" action={`${Config.webUrl}cardpayment.html`}>
                  <input type="hidden" name="url" value={`${this.state.cardUrl}`} />
                </form>
              : null
            }

            <iframe name="paymentFrame" width="100%" height="500" frameBorder="0"></iframe>
          </Modal.Body>
        </Modal>
      </div>);
  }
}
