import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Link } from 'react-router';
import serviceLocator from './../../base/service/service-locator';
import UpdatableComponent from '../../base/components/updatable-component';
import FormattedCurrencyShort from './../../base/components/formatted-currency-short';
import Loader from './../../app/components/loader';
import Config from 'Config';

export default class TransferListShort extends UpdatableComponent {
  acl = serviceLocator.get('AclService');
  utilsDate = serviceLocator.get('UtilsDate');
  transferGateway = serviceLocator.get('TradeGateway');
  utils = serviceLocator.get('Utils');
  logger = serviceLocator.get('Logger');

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      transferList: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getTransferList();
  }

  getTransferList = () => {
    if (!this.acl.isAllowed('transfer-list')){
      return;
    }

    this.setState({
      isLoading: true,
    });

    this.transferGateway
      .get({
        per_page: 5,
      })
      .then(data => {
        if (!this.utils.isComponentMounted(this)) {
          return;
        }

        this.setState({
          transferList: data.items,
          isLoading: false,
        });
      })
      .catch(error => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        this.setState({
          error,
        });
      });
  };

  renderTable() {
    return (
      <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Date </th>
                <th>Sell Amount</th>
                <th>Buy Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.transferList.map(
                (transfer, i) => (
                  <tr key={i}>
                    <td>{this.utilsDate.getUKDate(transfer.date)}</td>
                    <td>
                      <FormattedCurrencyShort value={transfer.clientSellAmount} currency={transfer.sellCurrency}/>
                    </td>
                    <td>
                      <FormattedCurrencyShort value={transfer.clientBuyAmount} currency={transfer.buyCurrency}/>
                    </td>
                    <td>
                      {transfer.transferId && +transfer.transferId > 0
                        ? <Link to={`/send-out?id=${transfer.transferId}`}>Edit</Link>
                        : <Link to={`/transfer-history`}>Show</Link>
                      }
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
      </div>
    );
  }

  render() {
    if (!this.state.transferList || !this.state.transferList.length) {
      return null;
    }

    return (
      <div className="in-progress-transfers">
        {this.state.isLoading ? <Loader /> : null}

        <ReactCSSTransitionGroup
          component="div"
          transitionName="table-responsive"
          transitionEnterTimeout={Config.transitionTimeout.opacity}
          transitionLeaveTimeout={Config.transitionTimeout.opacity}
        >
          <h2 className="sub-in-progress text-center">In Progress Transfers</h2>

          {this.state.transferList.length > 0
            ? this.renderTable()
            : <h6 className={"text-center loading-status"}>No transfers in progress</h6>
          }
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}
