import DataModel from './../../base/models/data-model';
import { signOut } from "./../../iclaus/methods";
import Fingerprint2 from 'fingerprintjs2';
import Config from 'Config';

const AUTO_UPDATE_TOKEN_INTERVAL = 300; // sec
export const LOGOUT_ACTION_DEFAULT = 'click';
export const LOGOUT_ACTION_TIMEOUT = 'timeout';
export const LOGOUT_ACTION_BROWSER_CLOSE = 'close';

export default class Auth extends DataModel {

  currentUser = {};
  authorizedByToken = false;
  deviceFingerprint = null;
  autoLogOutDisabled = false;

  constructor(httpClientAdapter = null) {
    super('', httpClientAdapter);

    this.subscriptions = {
      onAuthorize: [],
      onLogout: [],
      onCurrentUserLoaded: [],
      onJwtLoaded: [],
    };
  }

  ready() {
    this.onAuthorize(() => {
      window.addEventListener('beforeunload', this.onBrowserClose)
    });
    this.onLogout(() => {
      window.removeEventListener('beforeunload', this.onBrowserClose)
    });
    this.setAuthStatus(this.isAuthorized());
  }

  /**
   * @param {boolean} bool
   * @private
   */
  setAuthStatus(authStatus, authorizedByToken = false) {
    authStatus = !!authStatus;
    const prevAuthStatus = this.authStatus
    this.authStatus = authStatus;
    this.authorizedByToken = authorizedByToken;

    if (authStatus && prevAuthStatus !== authStatus) {
      this.publish('onAuthorize');
      this.startAutoUpdateToken();
      this.loadUserDetails();
    }

    if (!authStatus && prevAuthStatus === true) {
      this.publish('onLogout');
      this.stopAutoUpdateToken();
    }
  }

  /**
   * @returns {boolean}
   */
  isAuthorized() {
    if (this.jwt.isUserAuthorized()) {
      return true;
    }
    return false;
  }

  /**
   * @returns {boolean}
   */
  isVerified() {
    return this.jwt.isUserVerified();
  }

  isAuthorizedByToken() {
    return this.authorizedByToken;
  }

  get() {}
  post() {}

  setJwt(token) {
    if (this.jwt.set(token)) {
      return true;
    }
    return false;
  }

  loadUserDetails() {
    if (!this.authStatus) {
      return;
    }

    this.userDetailsGateway.get()
      .then((data) => {
        this.currentUser.clientId = data.user.clientId;
        this.currentUser.clientSignedId = data.user.clientSignedId;
        this.currentUser.regId = data.user.regId;
        this.currentUser.id = data.user.id;
        this.currentUser.login = data.user.email;
        this.currentUser.name = data.user.name;
        this.currentUser.email = data.user.email;
        this.currentUser.phoneCode = data.user.phoneCode;
        this.currentUser.phoneNumber = data.user.phoneNumber;
        this.currentUser.profileType = data.user.profileType;

        this.publish('onCurrentUserLoaded');
      });
  }

  isUserDetailsLoaded() {
    return !!this.currentUser.id || !!this.currentUser.clientId;
  }

  /**
   * @param {string} login
   * @param {string} password
   * @returns {Promise}
   */
  authorize(login, password) {
    const args = ['login', { email: login, password }];
    return this.executeCommand('post', args).then(response => {
      if (this.setJwt(response.token)) {
        this.setAuthStatus(true);
        return true;
      }

      throw response;
    });
  }

  authorizeByTokenQuery(query) {
    const queryObject = this.utils.getQueryObject(query);

    if (!queryObject.reg) {
      throw new Error('Authorization failed');
    }

    if (this.setJwt(queryObject.reg)) {
      this.setAuthStatus(true, true);
      return true;
    }

    return false;
  }

  /**
   *
   */
  stopAutoUpdateToken() {
    if (this._autoUpdateTokenTimer) {
      clearInterval(this._autoUpdateTokenTimer);
      this._autoUpdateTokenTimer = null;
    }
  }

  /**
   *
   */
  startAutoUpdateToken() {
    this.stopAutoUpdateToken();
    this._autoUpdateTokenTimer = setInterval(() => {
      this.getToken();
    }, AUTO_UPDATE_TOKEN_INTERVAL * 1000);
  }

  /**
   * @param callback
   */
  getToken(force = false) {
    return this.getDeviceFingerprintPromise()
      .then(deviceFingerprint => {

        return this.executeCommand('post', ['getToken', {force, deviceFingerprint}])
          .then(jwt => {
            if (!jwt || !jwt.token) {
              throw new Error('No token in getToken response');
            }
            this.jwt.set(jwt.token);

            return jwt.token;
          });
      });
  }

  /**
   *
   */
  logout(action) {
    this.executeCommand('get', [`logout?action=${action}`]);
    signOut();
    this.jwt.set(null);
    this.setAuthStatus(false, false);
    if (sessionStorage) {
      sessionStorage.clear();
    }
    window.location.href = window.location.origin ||
      // eslint-disable-next-line max-len, prefer-template
      (window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : ''));
  }

  /**
   * @param {function} callback
   */
  addListener(name, callback) {
    if (typeof callback !== 'function') {
      throw new Error('Listener must be a function');
    }
    if (Array.isArray(this.subscriptions[name])) {
      this.subscriptions[name].push(callback);
    } else {
      throw new Error(`Cannot subscribe to ${name}`);
    }
  }

  onAuthorize(callback) {
    this.addListener('onAuthorize', callback);
  }

  onLogout(callback) {
    this.addListener('onLogout', callback);
  }

  onCurrentUserLoaded(callback) {
    this.addListener('onCurrentUserLoaded', callback);
  }

  onJwtLoaded(callback) {
    this.addListener('onJwtLoaded', callback);
  }

  disableAutoLogOut() {
    this.autoLogOutDisabled = true;
  }

  enableAutoLogOut() {
    this.autoLogOutDisabled = false;
  }

  onBrowserClose = () => {
    if (Config.onBrowserCloseLogout && !this.autoLogOutDisabled) {
      this.logout(LOGOUT_ACTION_BROWSER_CLOSE);
    }
  };

  /**
   * @param {string} eventName
   * @private
   */
  publish(eventName) {
    if (this.subscriptions &&
      this.subscriptions[eventName] &&
      this.subscriptions[eventName].length
    ) {
      const callbacks = this.subscriptions[eventName];
      for (let i = 0; i < callbacks.length; i++) {
        callbacks[i]();
      }
    }
  }

  /**
   * @returns {*}
   */
  getCurrentUser() {
    return this.currentUser;
  }

  hasDeviceFingerprint() {
    return !!(this.deviceFingerprint && this.deviceFingerprint !== '');
  }

  getDeviceFingerprint() {
    return this.deviceFingerprint;
  }

  getDeviceFingerprintPromise() {
    if (!this.hasDeviceFingerprint()) {
      return Fingerprint2
        .getPromise({
          fonts: {extendedJsFonts: false},
          excludes: {enumerateDevices: true},
        })
        .then(components => {
          const values = components.map(comp => {
            const val = Array.isArray(comp.value) ? comp.value.join(',') : comp.value;
            return `${comp.key}:${val}`;
          });
          this.deviceFingerprint = Fingerprint2.x64hash128(values.join('|'), 31);

          return this.deviceFingerprint;
        });
    }

    return Promise.resolve(this.deviceFingerprint);
  }
}
