import React from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-formal';
import yup from 'yup';
import serviceLocator from './../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import FormGroup from './../../app/components/form-group';
import FormTextField from './../../base/components/form-text-field';
import Config from 'Config';
import CallUs from "../../base/components/call-us";

export default class PhoneVerification extends FormComponent {
  static propTypes = {
    skipConfirm: React.PropTypes.bool,
    phoneCode: React.PropTypes.string,
    phoneNumber: React.PropTypes.string,
  };

  static defaultProps = {
    skipConfirm: false,
  };

  constructor(props) {
    const state = {
      waitingTimeExpired: false,
      resendWaitingTimeExpired: false,
      isVerifyCodeValid: true,
      resent: false,
      timer: 0,
      formError: false,
      didNotReceivedClicked: false,
    };
    const schema = {
      code: yup.string().min(6).max(6),
    };

    super(props, state, schema);

    this.waitingTime = Config.smsVerificationCounterDown;
    this.phoneVerificationModel = serviceLocator.get('PhoneVerificationModel');
    this.ga = serviceLocator.get('GA');
    this.auth = serviceLocator.get('Auth');
  }

  startCounterDown(state, time, callback) {
    let counter = time;
    this.setState({ timer: counter });

    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (--counter < 0) {
        counter = 0;
      }

      this.setState({ timer: counter });

      if (counter === 0) {
        const obj = {};
        obj[state] = true;

        this.setState(obj);
        clearInterval(this.interval);
        if (typeof callback === 'function') {
          callback();
        }
      }
    }, 1000);
  }

  isProperFieldLength = (code) => {
    return code.length === 6
  }

  checkFieldValidation = (code) => {
    if(!this.isProperFieldLength(code)) {
      this.setState({formError: 'Code length should be exactly 6 digits. Please, re-check the code carefully.'})
    }
  }

  formOnChange = (value, updatedPaths, onValueUpdateHandler = null) => {
    const {code, ...rest} = value
    const codeWithOnlyDigits = code.replace(/[^0-9]/g, '')

    this._formOnChange({code: codeWithOnlyDigits, ...rest}, updatedPaths, onValueUpdateHandler);

    if (
      updatedPaths.indexOf('code') !== -1 &&
      this.state.formError
    ) {
      this.setState({ formError: false });
    }

    //Workaround for form error when exceeds limit
    if(codeWithOnlyDigits.length > 6) {
      this.setState({formError: 'Code length should be exactly 6 digits.'})
    } else {
      this.setState({ formError: false });
    }
  };

  formOnSubmit = (fields) => {
    if (this.props.show) {
      this._formOnSubmit(fields);
      this.verify(fields.code);
    }
  };

  verify(code) {
    this.phoneVerificationModel.verifyCode(code)
      .then((data) => {
        const {onVerificationSucceed, phoneCode, phoneNumber} = this.props;
        onVerificationSucceed(data);
        this.auth.loadUserDetails();

        this.ga.logEvent('SCA', 'Success');
      })
      .catch((error) => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        this.props.onVerificationFailed(error);

        const msg = error && ((error[0] && error[0].message) || error.message || (error.toString && error.toString()))
          || 'Unknown error';
        this.ga.logEvent('SCA', 'Error', { label: `${msg}` });
        this.setState({ formError: `${msg}` });
      });
  }

  onEntering = () => {
    if (!this.state.waitingTimeExpired) {
      this.startCounterDown('waitingTimeExpired', this.waitingTime);
    }

    if (document.querySelector('.wrapper-outer')) {
      document.querySelector('.wrapper-outer').classList.add('blur');
    }

    const input = document.getElementById("code-input");
    input && input.addEventListener("keyup", event => {
      if (event.key === "Enter" || event.keyCode === 13) {
        const el = document.querySelector('button.btn-mobile-verify');
        if (el) {
          el.click();
        }
      }
    });


    this.ga.logEvent('SCA', 'Open');
  };

  onExiting = () => {
    if (document.querySelector('.wrapper-outer')) {
      document.querySelector('.wrapper-outer').classList.remove('blur');
    }

    this.ga.logEvent('SCA', 'Close');
  };

  onDidNotReceive = () => {
    this.setState({ didNotReceivedClicked: true });

    this.ga.logEvent('SCA', 'Customer reports an error');
  };

  resend() {
    this.setState({ waitingTimeExpired: false, resent: true });

    const {phoneCode, phoneNumber} = this.props;
    this.phoneVerificationModel.setPhoneNumber(phoneNumber);
    this.phoneVerificationModel.setPhoneCode(phoneCode);
    const promise = this.phoneVerificationModel.verifyRequestResend();

    this.ga.logEvent('SCA', 'Resent');

    return promise.then((data) => {
      this.props.setFieldsValues({ mobileReference: data.reference });

      this.startCounterDown('resendWaitingTimeExpired', this.waitingTime);
    });
  }

  render() {
    const {skipConfirm, phoneCode, phoneNumber} = this.props;

    return (
      <Modal
        show={this.props.show}
        className="modal fade modal-phone-verification"
        id="modalPhoneVerification"
        onHide={this.props.onHide}
        onEntering={this.onEntering}
        onExiting={this.onExiting}
      >
        <Modal.Header>
          <div className="navbar-brand">CURRENCY SOLUTIONS</div>
        </Modal.Header>

        <Modal.Body>
          <p>
            Please enter the SMS verification 6-digit code sent to{' '}
            {phoneNumber
              ? <strong>+{`${phoneCode}`.replace('+','')} {phoneNumber}</strong>
              : <strong>your mobile</strong>}
          </p>

          <Form
            ref="form"
            component="div"
            className="form"
            schema={this.schema}
            value={this.state.value}
            errors={this.state.formErrors}
            onError={this.formOnError}
            onChange={this.formOnChange}
            onSubmit={this.formOnSubmit}
          >
            <div className="row">
              <div className="col-sm-4">
                <FormGroup fieldName="codeField">
                  <FormTextField
                    type="string"
                    name="code"
                    className="form-control input"
                    placeholder="XXXXXX"
                    id="code-input"
                  />

                  {this.state.formError
                    ? (<div className="form-group-message alert-danger">
                      <span className="validation-error">{this.state.formError}</span>
                    </div>)
                    : null
                  }
                </FormGroup>
              </div>

              <div className="col-sm-8">
              </div>
            </div>

            <FormGroup fieldName="common">
              <Form.Button type="submit" onClick={() => this.checkFieldValidation(this.state.value.code)} className="btn btn-sm btn-info btn-mobile-verify">Continue</Form.Button>
              {
                this.state.waitingTimeExpired && !this.state.didNotReceivedClicked
                  ? <Form.Button onClick={() => this.onDidNotReceive()} className="btn btn-link thin new-line">I did not receive an SMS verification code</Form.Button>
                  : null
              }
              {
                this.state.didNotReceivedClicked && !this.state.resent
                  ? <Form.Button onClick={() => this.resend()} className="btn btn-link thin new-line">Resend SMS verification code</Form.Button>
                  : null
              }
            </FormGroup>

            {
              this.state.resent
                ? <div>SMS verification code has been sent again</div>
                : null
            }
          </Form>
        </Modal.Body>

        <Modal.Footer>
          {skipConfirm && this.state.didNotReceivedClicked
            ? <CallUs
                message={'If you did not receive an SMS verification code, please call us on'}
                newLine
                left
              />
            : null
          }
        </Modal.Footer>
      </Modal>);
  }
}
