import React from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Form from 'react-formal'
import Dropzone from 'react-dropzone'
import serviceLocator from '../../base/service/service-locator'
import FormComponent from '../../base/components/form-component'
import FormMessage from './../../app/components/form-message'
import Loader from './../../app/components/loader'
import Config from 'Config'
import CallUs from './../../base/components/call-us'

const processReqs = reqs => reqs.reduce((res, req) => {
  if (!req || !req['CONTACT'] || !req['CONTACT']['ID']) {
    return res
  }

  if (!res[req['CONTACT']['ID']]) {
    res[req['CONTACT']['ID']] = {
      id: req['CONTACT']['ID'],
      name: req['CONTACT']['NAME'],
    }
  }
  if (!res[req['CONTACT']['ID']][req['DOC_REF_ID']] || !Array.isArray(res[req['CONTACT']['ID']][req['DOC_REF_ID']]['options'])) {
    res[req['CONTACT']['ID']][req['DOC_REF_ID']] = {
      id: req['DOC_REF_ID'],
      desc: req['DOC_REF_DESC'],
      name: req['DOC_REF_NAME'],
      options: [],
    }
  }
  res[req['CONTACT']['ID']][req['DOC_REF_ID']]['options'].push({
    id: req['entityId'],
    name: req['TEXT'],
  })

  return res
}, [])

export default class UploadDocumentsForm extends FormComponent {

  fileData = null
  uploadDocumentsGateway = serviceLocator.get('UploadDocumentsGateway')
  uploadDocumentsFinishGateway = serviceLocator.get('UploadDocumentsFinishGateway')
  utils = serviceLocator.get('Utils')
  ga = serviceLocator.get('GA')

  static propTypes = {
    header: React.PropTypes.any,
    subtitle: React.PropTypes.any,
    onSuccess: React.PropTypes.func,
    onCancel: React.PropTypes.func,
    showForm: React.PropTypes.bool,
    reqs: React.PropTypes.array,
    loading: React.PropTypes.bool,
  }

  static defaultProps = {
    header: 'Documents required',
    subtitle: 'In order to enable you to start trading as soon as possible, please provide the following additional documents.',
  }

  constructor(props) {
    const state = {
      isLoading: false,
      hasUploadedFiles: false,
      uploadedFiles: [],
      buttonLabel: 'or browse for file',
      isFileInvalid: false,
      dropRejected: false,
    }

    super(props, state)
  }

  componentWillMount() {
    if (this.utils.isMobileDevice()) {
      this.setState({buttonLabel: 'Browse for file or take a photo'})
    }
  }

  uploadSelectedDocument = (contactId, refId, id, file) => {
    this.setState({
      isLoading: true,
      formErrors: {},
    })

    const formData = new FormData()
    formData.append('file', file, file.name)
    this.uploadDocumentsGateway.post(formData)
      .then(this.onUploadSuccess(contactId, refId, id, this.utils.getFileName(file.name)))
      .catch(failResponse => {
        this.onUploadFail(failResponse)
      })
  }

  formOnSubmit = () => {
    const { uploadedFiles } = this.state

    this.uploadDocumentsFinishGateway.post(uploadedFiles, true)
      .then(result => {
        if (result.success) {
          this.props.onSuccess()
        }
      })
      .catch(failResponse => {
        this.onUploadFail(failResponse)
      })
  }

  onUploadFail = (failResponse) => {
    let proceed = true
    if (failResponse instanceof Array) {
      failResponse.forEach(error => {
        if (proceed && error.code === 'FILE_UPLOAD_VALIDATION_FAILED') {
          this.setState({isFileInvalid: true})
          proceed = false
        }
      })
    }

    this.setState({
      isLoading: false,
    })

    this.logger.reportedErrors = {}

    if (proceed) {
      this._onFailResponse(failResponse)
    }
  }

  onUploadSuccess = (contactId, refId, id, fileName) => (data) => {
    if (data && data.path && data.success) {
      this.setState(prevState => {
        const files = prevState.uploadedFiles.slice(0)
        files.push({
          id,
          refId,
          contactId,
          name: fileName || 'unknown',
          path: data.path,
        })

        return {
          isLoading: false,
          hasUploadedFiles: true,
          uploadedFiles: files,
          isFileInvalid: false,
        }
      })
    }
  }

  onDrop = (contactId, refId, id) => (files) => {
    this.setState({
      dropRejected: false,
    })

    if (!files.length || !files[0]) {
      return
    }

    files.map(file => this.uploadSelectedDocument(contactId, refId, id, file))
  }

  onDropRejected = () => {
    this.setState({
      dropRejected: true,
    })
  }

  onFileRemove = (id, name) => {
    let files = this.state.uploadedFiles
    files = files.filter((value) => (
      parseInt(value.id, 10) !== parseInt(id, 10) || value.name !== name
    ))

    this.setState({
      uploadedFiles: files,
    })
  }

  onDocTypeChange = (id, name) => (e) => {
    e.preventDefault();
    let files = this.state.uploadedFiles
    files = files.map(file => {
      if (file.id === id && file.name === name) {
        file.id = e.target.value
      }

      return file
    })

    this.setState({
      uploadedFiles: files,
    })
  }

  renderDropzone() {
    const { reqs } = this.props
    const { uploadedFiles, isLoading } = this.state
    const contacts = processReqs(reqs)

    return (
      <div>
        {contacts && contacts.map(contact => {
          return Object.keys(contact).map(contactKey => {
            if (contactKey === 'name' || contactKey === 'id') {
              return null
            }

            const reqGroup = contact[contactKey]
            const selected = uploadedFiles ? uploadedFiles.filter(
              f => (f.refId === reqGroup.id && f.contactId === contact.id)
            ) : []
            const req = reqGroup.options[0]

            return (
              <div key={`${contact.id}_${reqGroup.id}`} className="form-group-row drop-zone text-center">
                <div className={`descr text-center${selected.length === 0 ? ' missed' : ''}`}>
                  {contact.name}: {reqGroup.name}{selected.length === 0 ? ' [missed]' : ''}
                </div>
                <Dropzone
                  onDrop={this.onDrop(contact.id, reqGroup.id, req.id)}
                  onDropRejected={this.onDropRejected}
                  multiple={true}
                  accept="image/jpeg,image/gif,image/png,image/bmp,application/pdf,image/tiff"
                  style={{'padding': '10px 0'}}
                  onClick={e => e.preventDefault()}
                >
                  <div className="file-name text-center">Please upload {reqGroup.options.map((req, key) => (
                    <span key={key}>{key > 0 ? ' or ' : ''}<b>"{req.name}"</b></span>
                  ))}</div>
                  <img src="/images/icon-cloud.svg" height="40" />
                  <label className="text-center hidden-xs hidden-sm">Drop files to upload</label>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-sm btn-search btn-upload-file"
                    >{this.state.buttonLabel}</button>
                    {isLoading && <Loader/>}
                  </div>
                </Dropzone>
                {selected.map((sel, key) => (
                  <div className="file-name text-center" key={key}>
                    <div className="uploaded" />
                    {reqGroup.options.length === 1
                      ? <b>{req.name}</b>
                      : <select className="file-select" onChange={this.onDocTypeChange(sel.id, sel.name)}>
                          {reqGroup.options.map(op => (
                            <option value={op.id} key={op.id}>{op.name}</option>
                          ))}
                        </select>
                    }
                    <span className="name">{sel.name}</span>
                    <a href="#" onClick={e => {e.preventDefault(); this.onFileRemove(sel.id, sel.name);}}>delete</a>
                  </div>
                ))}
                <div
                  className="document-name text-center"
                  dangerouslySetInnerHTML={{
                    __html: reqGroup.desc || 'You can upload your files as a pdf, a scanned photo or an e-document.',
                  }}
                />
              </div>
            )
          })
        })}
        <FormMessage for="file"/>
      </div>
    )
  }

  renderErrorMessage() {
    if (!this.state.isFileInvalid && !this.state.dropRejected) {
      return null
    }

    return (
      <div className="form-group-row">
        <div className="col-xs-12">
          <ReactCSSTransitionGroup
            component="div"
            transitionName="form-group-message"
            transitionEnterTimeout={Config.transitionTimeout.opacity}
            transitionLeaveTimeout={Config.transitionTimeout.opacity}
          >
            <div className="form-group-message form-group-message-file-invalid alert-danger">
              {/* eslint-disable max-len */}
              Minimum file size: 1Kb, maximum file size: 10Mb, accepted file types are: *.jpeg, *.png, *.giff, *.bmp, *.tiff,
              *.pdf
              {/* eslint-enable max-len */}
            </div>
          </ReactCSSTransitionGroup>
        </div>
      </div>)
  }

  renderSuccessMessage() {
    return (
      <div className="upload-documents">
        <div className="clearfix">
          <div className="col-sm-8 col-sm-offset-2">
            <div className="success-icon"></div>
            <h1 className="text-center">Documents successfully uploaded</h1>
            <div className="row">
              <p className="intro-text text-center col-md-10 col-md-offset-1">
                Your documents were successfully uploaded.<br/><br/>
                <CallUs message="If you have any questions, please call us" newLine={false}/>
              </p>
            </div>
          </div>
        </div>
      </div>)
  }

  renderNoDocs() {
    return (
      <div className="upload-documents">
        <div className="clearfix">
          <div className="col-sm-10 col-sm-offset-1">
            <h1 className="text-center">Documents are processing</h1>
            <div className="row">
              <p className="intro-text text-center col-md-10 col-md-offset-1">
                Thanks for completing the registration form, we are just processing your information.<br /><br />
                Sometimes we may need to ask for extra documents, in order to complete your registration,
                a member of our team will be in touch.<br/><br/>
                <CallUs message="If you have any questions, please call us" newLine={false}/>
              </p>
            </div>
          </div>
        </div>
      </div>)
  }

  render() {
    const { header, subtitle, onCancel, reqs, loading } = this.props
    const { hasUploadedFiles, uploadedFiles } = this.state

    if (!this.props.showForm) {
      return this.renderSuccessMessage()
    }

    if (loading) {
      return <Loader />
    }

    if (!loading && (!reqs || !reqs.length)) {
      return this.renderNoDocs()
    }

    return (
        <div className="make-transfer-step upload-documents">
          <div className="clearfix">
            <div className="upload-documents">
              <h1 className="text-center">{header}</h1>
              <div className="row">
                {subtitle !== '' ?
                  <p className="intro-text text-center col-md-10 col-md-offset-1">{subtitle}</p> : null}
              </div>
              <Form
                ref="form"
                component="div"
                className="form form-horizontal"
                schema={this.schema}
                value={this.state.value}
                errors={this.state.formErrors}
                onError={this.formOnError}
                onChange={this.formOnChange}
                onSubmit={this.formOnSubmit}
                encType="multipart/form-data"
              >
                <div className="form-group-wrap">
                  {this.renderDropzone()}
                  {this.renderErrorMessage()}
                </div>
                <div className="row">
                  <div className="btn-wrap text-center">
                    <Form.Button
                      type="submit"
                      className="btn btn-default btn-block btn-cancel-upload"
                      onClick={onCancel}
                    >Cancel</Form.Button>
                    <Form.Button
                      type="submit"
                      className="btn btn-primary btn-block btn-confirm-upload"
                      disabled={
                        !hasUploadedFiles || !uploadedFiles || !reqs ||
                        !uploadedFiles.length
                      }
                    >Confirm</Form.Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )
  }

  formOnError = (formErrors, onErrorSetHandler = null) => {
    if (onErrorSetHandler) {
      onErrorSetHandler()
    }
  }
}
