import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import serviceLocator from "../../base/service/service-locator";
import Config from 'Config';
import QuoteTable from './quote-table';
import UpdatableComponent from "../../base/components/updatable-component";
import Payment, {TYPE_CREDIT, TYPE_DEBIT, TYPE_TRANSFER} from './../../payment/components/payment';
import MakeTransferSummary from './make-transfer-summary';
import ModalErrorMessage from './../../app/components/modal-error-message';
import CallUs from './../../base/components/call-us';

export default class QuoteConfirmation extends UpdatableComponent {
  static propTypes = {
    quoteValues: React.PropTypes.object,
    quoteTimeout: React.PropTypes.number,
    bookAllowed: React.PropTypes.bool,
    onDone: React.PropTypes.func,
    onStartOver: React.PropTypes.func,
    showCardTransfer: React.PropTypes.bool,
    cardType: React.PropTypes.string,
    onChangeCardType: React.PropTypes.func,
    smallSendOutValues: React.PropTypes.object,
    onPaymentSubmit: React.PropTypes.func,
    onPaymentFail: React.PropTypes.func,
    cardStatus: React.PropTypes.string,
  };

  state = {
    agreementApproved: false,
    showAgreementWarning: false,
    paymentButtonDisabled: true,
    showSummary: false,
    showCardPaymentOtherOptions: false,
  };

  static childContextTypes = {
    submitPaymentForm: React.PropTypes.func,
    onPaymentFailedResponse: React.PropTypes.func,
  };

  hasDetailsApproved = true;
  utils = serviceLocator.get('Utils');
  ga = serviceLocator.get('GA');

  componentDidMount() {
    this.utils.scrollScreenTo(0);
    this.ga.logPageView('/transfer-confirmation');
  }

  getChildContext() {
    return {
      submitPaymentForm: this.onSubmitPaymentForm,
      onPaymentFailedResponse: this.onPaymentFailedResponse,
    };
  }

  onSubmitPaymentForm = (...res) => {
    const {onPaymentSubmit} = this.props;
    this.setState({
      paymentButtonDisabled: true,
    });
    onPaymentSubmit(...res);

    this.ga.logEvent('Trade', 'Payment', {
      label: 'Submitted',
    });
  };

  onPaymentFailedResponse = (...res) => {
    this.setState({
      paymentButtonDisabled: false,
    }, () => {
      this.setState({ showCardPaymentOtherOptions: true });
      this.props.onPaymentFail(...res);
    });

    this.ga.logEvent('Trade', 'Payment', {
      label: 'Failed',
    });
  };

  onAgreementApprove = () => {
    this.setState(state => ({
      agreementApproved: !state.agreementApproved,
      paymentButtonDisabled: state.agreementApproved,
      showAgreementWarning: state.agreementApproved,
    }));
  };

  onClientConfirmed = () => {
    const {onDone} = this.props;

    this.hasDetailsApproved = true;
    this.setState({
      paymentButtonDisabled: this.props.cardType === TYPE_TRANSFER,
      showSummary: false,
    });

    this.ga.logEvent('Trade', 'QuoteConfirmed', {
      label: this.props.cardType,
    });

    onDone();
  };

  onClientDeclined = () => {
    const {onStartOver} = this.props;

    this.hasDetailsApproved = false;
    this.setState({
      showSummary: false,
      showCardPaymentOtherOptions: false,
    }, onStartOver);
  };

  onBuyCurrencyClick = (event) => {
    event.preventDefault();

    if (!this.state.agreementApproved) {
      // this.hasDetailsApproved = false;
      this.setState({
        showAgreementWarning: true,
      });
      return;
    }

    if (!this.hasDetailsApproved) {
      this.setState({
        showSummary: true,
      });
      return;
    }

    this.onClientConfirmed();
  };

  refreshCardPayment() {
    const {onChangeCardType} = this.props;
    this.setState({
      showCardPaymentOtherOptions: false,
    });
    onChangeCardType(TYPE_TRANSFER);
    setTimeout(() => {
      onChangeCardType(TYPE_DEBIT);
    }, 300);
  }

  switchToBankTransfer() {
    const {onChangeCardType} = this.props;
    this.setState({
      showCardPaymentOtherOptions: false,
    });
    onChangeCardType(TYPE_TRANSFER);
  }

  renderCardPaymentFailureOptions() {
    return (
      <div className="form-group card-payment-options">
        <button
          name="submit"
          className="btn btn-lg btn-block btn-info"
          onClick={this.props.onStartOver}
        >
          Please Try Again
        </button>
      </div>
    );
  }

  render() {
    const {quoteValues, quoteTimeout, bookAllowed, cardType, onChangeCardType,
      showCardTransfer, smallSendOutValues, cardStatus} = this.props;
    const {showCardPaymentOtherOptions, showSummary, agreementApproved, showAgreementWarning} = this.state;

    return (
      <div>
        <div className="clearfix quote-confirmation row">
          <div className="col-md-8 col-md-offset-2">
            <div className="table-responsive table-quote-wrap">
              <table className="table">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                </colgroup>
                <tbody>
                  <tr>
                    <td className={'text-left'}>Recepient</td>
                    <td className={'text-right'}>
                      {smallSendOutValues ? smallSendOutValues.beneficiaryId.label : 'Add a new one or specify later'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ReactCSSTransitionGroup
          component="div"
          transitionName="make-transfer-rate"
          transitionEnterTimeout={Config.transitionTimeout.opacity}
          transitionLeaveTimeout={Config.transitionTimeout.opacity}
        >
          <QuoteTable
            quoteAvailable={true}
            quoteValues={quoteValues}
            quoteTimeout={quoteTimeout}
            quoteLoading={false}
            creditCardChargeApplied={cardType === TYPE_CREDIT}
            showRate={false}
            justify
            />
        </ReactCSSTransitionGroup>

        {bookAllowed
          ? <Payment
              currency={quoteValues.sellCurrency}
              buyCurrency={quoteValues.buyCurrency}
              amount={parseFloat(quoteValues.totalAmount)}
              changeCardType={onChangeCardType}
              quoteValues={quoteValues}
              showCardOptions={showCardTransfer}
              cardType={cardType}
              agreementApproved={agreementApproved}
              onAgreementApprove={this.onAgreementApprove}
              showAgreementWarning={showAgreementWarning}
              cardStatus={cardStatus}
            />
          : null
        }

        {bookAllowed && cardType === TYPE_TRANSFER
          ? <div className="row">
              <div className="col-md-8 col-md-offset-2 make-transfer-submit-container">
                <div className="make-transfer-submit-block">
                  <div className="clearfix btn-wrap">
                    <button
                      type="button"
                      onClick={this.onBuyCurrencyClick}
                      disabled={this.state.paymentButtonDisabled}
                      className="btn btn-primary btn-block btn-buy-currency"
                    >Book Now</button>
                  </div>
                </div>
              </div>
            </div>
          : null
        }

        <MakeTransferSummary
          quoteValues={quoteValues}
          showModal={showSummary}
          onConfirm={this.onClientConfirmed}
          onReQuote={this.onClientDeclined}
          cardType={cardType}
          smallSendOutValues={smallSendOutValues}
        />

        {showCardTransfer
          ? <ModalErrorMessage
              onCloseHandler={() => { document.location = document.location; }}
              show={showCardPaymentOtherOptions}
              title="Oops... Payment unsuccessful"
              id="card-payment-error"
              titleText="Sorry, we have been unable to
              process the card details you have entered"
              children={this.renderCardPaymentFailureOptions()}
              noCloseButton
              footer={<CallUs
                message="If you require further assistance, our customer
                service team will be happy to help you"
              />}
            />
          : null
        }
      </div>
    );
  }
}
