import "core-js/stable";
import DataClientAdapterInterface from './../adapter/data-client-adapter-interface';
import CustomEvent from 'custom-event';
import { getToken as getClausToken } from './../../iclaus/methods';

let getTokenPromise = null;

export default class DataModel {

  /**
   * @param {string} route
   * @param {DataClientAdapterInterface} dataClient
   */
  constructor(route, dataClient = null) {
    this.setRoute(route);
    if (dataClient) {
      this.setDataClient(dataClient);
    }
    this.queues = {
      captcha: [],
      device: [],
    };
  }

  /**
   * @returns {string}
   */
  getRoute() {
    return this.route;
  }

  /**
   * @param route
   * @returns {DataModel}
   */
  setRoute(route) {
    this.route = route;
    return this;
  }

  /**
   * @returns {DataClientAdapterInterface}
   */
  getDataClient() {
    return this.dataClient;
  }

  /**
   * @param {DataClientAdapterInterface} dataClient
   */
  setDataClient(dataClient) {
    if (!(dataClient instanceof DataClientAdapterInterface)) {
      throw new Error('DataClient must be instanceof DataClientAdapterInterface');
    }
    this.dataClient = dataClient;
  }

  /**
   * @returns {object}
   * @protected
   */
  _getHeaders() {
    const headers = {};
    if (this.jwt.get()) {
      headers['Authorization'] = this.jwt.get();
    }
    return headers;
  }

  /**
   * @param {string} command
   * @param {Array} args
   * @returns {axios.Promise|Promise.<T>|*}
   * @private
   */
  _executeCommand(command, args) {
    const client = this.getDataClient();
    const asJson = args.length === 3 ? args.pop() : false;
    args.push({
      headers: this._getHeaders(),
      asJson,
    });
    return client[command](...args)
      .then(response => this._onSuccess(response));
  }

  executeCommand(command, args) {
    if (!this.jwt.getTokenSession() &&
      args[0] !== 'log'
    ) {
      if (!getTokenPromise) {
        const client = this.getDataClient();
        getTokenPromise = getClausToken()
          .then(data => {
            if (!data.token) {
              throw new Error('No token in Claus getToken response');
            }

            return client
              .post('getToken', args[0] === 'getToken' && args.length > 1 ? args[1] : {}, {
                headers: {
                  'Authorization': data.token,
                },
              })
              .then(response => this._onSuccess(response))
          });
        getTokenPromise
          .then(jwt => {
            if (!jwt || !jwt.token) {
              throw new Error('No token in getToken response');
            }
            this.jwt.set(jwt.token);
            getTokenPromise = null;
            if (!this.jwt.getTokenSession()) {
              throw new Error('Token from getToken response has no session');
            }
            return true;
          })
          .catch(error => {
            getTokenPromise = null;
            this.getDataClient().logError(error);
          });
      }
      return new Promise((resolve, reject) => {
        getTokenPromise.then(isJwtLoaded => {
          if (isJwtLoaded) {
            if (args[0] === 'getToken') {
              resolve({ token: this.jwt.get() });
              return;
            }

            this.queuedExecuteCommand(command, args, resolve, reject);
          } else {
            reject('Token is not loaded');
          }
        });
      });
    }
    return new Promise((resolve, reject) => {
      this.queuedExecuteCommand(command, args, resolve, reject);
    });
  }

  queuedExecuteCommand(command, args, resolve, reject) {
    this._executeCommand(command, args)
      .then(data => {
        if (data && (data.reference || data.second_factor) &&
          Array.isArray(args) &&
          ![
            'card-transaction/status',
            'user/mobile-verify/request',
            'user/mobile-verify/request-resend',
          ].includes(args[0])
        ) {
          const event = new CustomEvent('onTwoFactorRequired', {
            detail: { reference: data.reference, secondFactor: data.second_factor || 'sms' },
          });
          document.dispatchEvent(event);
        }
        resolve(data);
        return data;
      })
      .catch(failedData => {
        if (failedData.length && failedData[0].code === 'ACCESS_DENIED') {
          return;
        }
        if (failedData.length && failedData[0].code === 'CAPTCHA_REQUIRED') {
          this.addRequestToQueue('captcha', command, args, resolve, reject);
          const event = new CustomEvent('onCaptchaRequired');
          document.dispatchEvent(event);
          return;
        }
        if (failedData.code === 'DEVICE_VERIFICATION_REQUIRED') {
          this.addRequestToQueue('device', command, args, resolve, reject);
          const event = new CustomEvent('onDeviceVerificationRequired');
          document.dispatchEvent(event);
          return;
        }
        if (failedData instanceof Error && !failedData.length) {
          this.getDataClient().logError(failedData);
        } else {
          reject(failedData);
        }
      });
  }

  /**
   * @param response
   * @returns {*}
   * @private
   */
  _onSuccess(response) {
    if (response.content && response.content.hasOwnProperty('error')) {
      throw response.content.error;
    }

    if (response.error && response.error.code && response.error.code !== '') {
      throw response.error;
    }

    if (response.content && response.content.hasOwnProperty('data')) {
      return response.content.data;
    }

    if (response.hasOwnProperty('data')) {
      return response.data;
    }

    throw new Error(`Invalid response data ${this.route} ${JSON.stringify(response)}`);
  }

  getUrlByData(data = null) {
    let url = this.getRoute();
    if (typeof data === 'number') {
      url += `/${data}`;
    } else if (typeof data === 'string') {
      url += `?${data}`;
    } else if (data && typeof data === 'object') {
      if ('id' in data) {
        url += `/${data.id}`;
        // eslint-disable-next-line no-param-reassign
        delete data.id;
      }
      url += `?${this.getDataClient().normalizeParams(data)}`;
    }

    return url;
  }

  /**
   * @param {Object|number|null} data
   * @returns {axios.Promise|Promise.<T>|*}
   */
  get(data = null) {
    return this.executeCommand('get', [this.getUrlByData(data)]);
  }

  /**
   * @param {Object} data
   * @param asJson
   * @returns {axios.Promise|Promise.<T>|*}
   */
  post(data = null, asJson = false) {
    return this.executeCommand('post', [this.getRoute(), data, asJson]);
  }

  del(data = null) {
    return this.executeCommand('del', [this.getUrlByData(data)]);
  }

  getJwt() {
    return this.jwt;
  }

  addRequestToQueue(queueName, command, args, resolve, reject) {
    if (this.queues[queueName].length === 0) {
      switch (queueName) {
        case 'captcha':
          document.addEventListener('onCaptchaVerified', () => {
            this.resendQueue(queueName);
          });
          break;
        case 'device':
          document.addEventListener('onDeviceVerified', () => {
            this.resendQueue(queueName);
          });
          break;
      }
    }
    this.queues[queueName].push({
      command,
      args,
      resolve,
      reject,
    });
  }

  resendQueue(queueName) {
    if (this.queues[queueName].length) {
      this.queues[queueName].forEach(queuedRequest => {
        this.queuedExecuteCommand(
          queuedRequest.command,
          queuedRequest.args,
          queuedRequest.resolve,
          queuedRequest.reject
        );
      });
      this.queues[queueName] = [];
    }
  }
}
