import React from "react";
import serviceLocator from "./../../base/service/service-locator";
import Loader from "./../../app/components/loader";
import UpdatableComponent from "../../base/components/updatable-component";
import PersonalDetails from "./my-details-form";
import ChangePassword from "./user-password";
import AddUserModal from "./add-user-modal";
import UploadModal from "./upload-modal";
import SVGInline from "react-svg-inline";

export default class MyDetails extends UpdatableComponent {
	auth = serviceLocator.get("Auth");
	logger = serviceLocator.get("Logger");
	currentDetailsGateway = serviceLocator.get("UserCurrentDetails");
	userFileRequestsGateway = serviceLocator.get("UserFileRequestsGateway");

	state = {
		isLoading: true,
		user: null,
		userData: null,
		addUserOpened: false,
		uploadOpened: false,
		reqs: [],
		reqsLoading: true,
	};

	componentDidMount() {
		if (this.auth.isUserDetailsLoaded()) {
			this.onCurrentUserLoaded();
		} else {
			this.auth.addListener("onCurrentUserLoaded", () => {
				this.onCurrentUserLoaded();
			});
		}
	}

	loadUser() {
		this.currentDetailsGateway
			.get()
			.then((data) => {
				this.setState({
					isLoading: false,
					userData: data,
				});
			})
			.catch((error) => {
				if (this.logger.handlePromiseCatch(error)) {
					return;
				}
				this.setState({
					isLoading: false,
				});
			});
	}

	getFileRequests = () => {
		this.userFileRequestsGateway
			.get()
			.then((data) => {
				this.setState({
					reqs: data.items || [],
					uploadOpened: false,
					reqsLoading: false,
				});
			})
			.catch((error) => {
				if (this.logger.handlePromiseCatch(error)) {
					return;
				}
				this.setState({
					reqs: [],
					uploadOpened: false,
					reqsLoading: false,
				});
			});
	};

	onCurrentUserLoaded() {
		this.setState({
			user: this.auth.getCurrentUser(),
		});
		this.loadUser();
		this.getFileRequests();
	}

	onAddUserClicked = (event) => {
		if (event) {
			event.preventDefault();
		}

		this.setState((state) => ({
			addUserOpened: !state.addUserOpened,
		}));
	};

	onUploadClicked = (event) => {
		if (event) {
			event.preventDefault();
		}

		this.setState((state) => ({
			uploadOpened: !state.uploadOpened,
		}));
	};

	onUploadClosed = (event) => {
		if (event) {
			event.preventDefault();
		}

		this.setState((state) => ({
			uploadOpened: false,
		}));

		this.getFileRequests();
	};

	render() {
		const { isLoading, user, userData, addUserOpened, uploadOpened, reqs, reqsLoading } = this.state;

		if (isLoading) {
			return (
				<div className="tab-content platform-tabs">
					<section className="tab-pane fade in active tab-my-details" id="tab-my-details">
						<Loader />
					</section>
				</div>
			);
		}

		return (
			<div className="tab-content platform-tabs">
				<section className="tab-pane fade in active tab-my-details" id="tab-my-details">
					<div className="clearfix">
						<div className="my-details-header-container">
							<div className="col-md-9">
								<div className="my-details-header">
									<div className="row">
										<div className="col-md-6">
											<img src="/images/icon-user.svg" />
											<div className="my-details-name">{user.name}</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="my-details-btn-container">
									<div className="row">
										<div
											className={`btn-docs-container col-md-6 text-center${
												reqs && reqs.length ? "" : " disabled"
											}`}
										>
											<button
												className="btn-my-details"
												onClick={this.onUploadClicked}
											>
												<div className="btn-icon btn-docs">
													<SVGInline
														svg={`<svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="50\"
                                width="43\"
                                viewBox="0 0 43 50">
                                <path
                                  d="M 5.5106563,8.29e-5 C 3.659145,8.29e-5 2.15615,1.9421403 2.15615,4.3255765 V 45.674506 C 2.15615,48.057942 3.659145,50 5.5106563,50 H 37.489345 c 1.851511,0 3.354505,-1.942058 3.354505,-4.325494 V 13.30822 a 1.2582094,1.2582094 0 0 0 -0.01,-0.135859 1.2582094,1.2582094 0 0 0 -0.157835,-0.483495 1.2786681,1.2786681 0 0 0 0,-0.002 1.2582094,1.2582094 0 0 0 -0.06794,-0.105887 L 31.125932,0.3057162 a 0.6649074,0.6649074 0 0 0 -0.04595,-0.057939 0.6649074,0.6649074 0 0 0 -0.427553,-0.2377525 0.6649074,0.6649074 0 0 0 -0.01,-0.00205 A 0.73651281,0.73651281 0 0 0 30.562504,0 Z m 0,2.0658478 H 29.76539 v 7.9277418 c 0,2.3834375 1.503234,4.3274925 3.344516,4.3274925 h 6.137607 v 31.353341 a 2.0458689,2.0458689 0 0 1 -1.758168,2.259646 H 5.5106563 A 2.0458689,2.0458689 0 0 1 3.7524875,45.674506 V 4.3255765 A 2.0458689,2.0458689 0 0 1 5.5106563,2.0659307 Z m 25.8510707,1.4724661 6.740979,8.7269092 h -4.9928 A 2.0458689,2.0458689 0 0 1 31.361727,9.9936725 Z M 7.936129,9.9217487 V 11.741851 H 25.539792 V 9.9217487 Z m 0,5.2485313 v 1.820105 H 25.539792 V 15.17028 Z m 0,5.256525 v 1.822102 h 27.363496 v -1.822102 z m 0,5.248532 v 1.820106 h 27.363496 v -1.820106 z m 0,4.757046 A 0.9410997,0.9410997 0 0 0 7.13896,31.455317 v 11.793715 a 0.9410997,0.9410997 0 0 0 0.797169,1.022936 h 14.66872 a 0.93087035,0.93087035 0 0 0 0.79717,-1.032926 V 31.455317 a 0.93087035,0.93087035 0 0 0 -0.79717,-1.022934 z m 17.164121,0.489489 v 1.822103 H 35.39153 V 30.921872 Z M 8.733299,32.478251 H 21.81767 v 9.747847 H 8.733299 Z m 16.366951,3.692155 v 1.820104 h 10.455109 v -1.820104 z m 0,5.248533 v 1.820103 h 10.455109 v -1.820103 z" />
                              </svg>`}
													/>
												</div>
												Documents
											</button>
										</div>
										<div className="col-md-6 text-center">
											<button
												className="btn-my-details"
												onClick={this.onAddUserClicked}
											>
												<div className="btn-icon btn-adduser">
													<SVGInline
														svg={`<svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="50"
                                  width="43"
                                  viewBox="0 0 43 50">
                                  <path
                                    id="path10"
                                    d="M 21.060656,0.00917113 A 10.135956,10.135956 0 0 0 21.5,20.273311 10.135956,10.135956 0 0 0 31.627693,10.145617 10.135956,10.135956 0 0 0 21.060656,0.00917113 Z M 21.5,1.5600076 A 8.5945017,8.5945017 0 0 1 30.076858,10.145617 8.5855397,8.5855397 0 1 1 21.5,1.5600076 Z M 12.377024,21.365547 A 8.693083,8.693083 0 0 0 3.6933925,30.050928 V 43.143765 C 3.6933925,46.468645 9.9301335,50 21.5,50 33.069867,50 39.306607,46.513454 39.306607,43.143765 V 30.050928 a 8.693083,8.693083 0 0 0 -8.683631,-8.685381 z m 18.210945,1.515828 a 7.169553,7.169553 0 0 1 7.169553,7.169553 v 13.092837 c 0,2.473495 -6.712215,5.305399 -16.292529,5.305399 -9.580315,0 -16.2575225,-2.796056 -16.2575225,-5.305399 V 30.085936 a 7.169553,7.169553 0 0 1 7.1695535,-7.169553 z m -2.536299,7.250071 a 0.50186871,0.50186871 0 0 0 -0.511111,0.50936 v 5.531199 H 22.01111 A 0.50186871,0.50186871 0 0 0 21.5,36.655109 v 1.353044 a 0.51083065,0.51083065 0 0 0 0.51111,0.51111 h 5.529449 v 5.529448 a 0.51083065,0.51083065 0 0 0 0.511111,0.51111 h 1.353042 a 0.51979259,0.51979259 0 0 0 0.511111,-0.51111 v -5.503192 h 5.529448 a 0.51979259,0.51979259 0 0 0 0.511111,-0.511111 V 36.655109 A 0.51083065,0.51083065 0 0 0 35.44527,36.172005 h -5.529448 v -5.531199 a 0.51083065,0.51083065 0 0 0 -0.511111,-0.50936 z" />
                                </svg>`}
													/>
												</div>
												Add user
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="clearfix my-details-form-container">
						<div className="col-md-6">
							<PersonalDetails initialValues={{ ...user, ...userData }} />
						</div>

						<div className="col-md-6 my-details-change-pwd">
							<ChangePassword />
						</div>
					</div>

					<AddUserModal show={addUserOpened} onCloseHandler={this.onAddUserClicked} />

					{reqs && reqs.length ? (
						<UploadModal
							show={uploadOpened}
							onCloseHandler={this.onUploadClosed}
							reqs={reqs}
							loading={reqsLoading}
						/>
					) : null}
				</section>
			</div>
		);
	}
}
