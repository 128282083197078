import React from 'react';
import UpdatableComponent from '../../base/components/updatable-component';
import UploadDocumentsForm from './upload-documents-form';
import serviceLocator from "../../base/service/service-locator";

export default class UploadDocuments extends UpdatableComponent {
  auth = serviceLocator.get('Auth');
  logger = serviceLocator.get('Logger');
  userFileRequestsGateway = serviceLocator.get('UserFileRequestsGateway');

  state = {
    user: null,
    showForm: true,
    reqs: [],
    loading: true,
  };

  onSuccess = () => {
    this.setState({
      showForm: false,
    });
  };

  componentDidMount() {
    if (this.auth.isUserDetailsLoaded) {
      this.onCurrentUserLoaded();
    } else {
      this.auth.addListener('onCurrentUserLoaded', () => { this.onCurrentUserLoaded(); });
    }
  }

  onCurrentUserLoaded() {
    this.setState({
      user: this.auth.getCurrentUser(),
    });

    this.userFileRequestsGateway
      .get()
      .then(data => {
        this.setState({
          reqs: data.items || [],
          loading: false,
        });
      })
      .catch(error => {
        if (this.logger.handlePromiseCatch(error)) {
          return;
        }
        this.setState({
          reqs: [],
          loading: false,
        });
      });
  }

  renderForm() {
    const { user, reqs, loading } = this.state;
    if (!user) {
      return null;
    }

    /* eslint-disable max-len */
    return (
      <UploadDocumentsForm
        subtitle={<span>
          Your registration hasn&apos;t processed yet. Here you can upload additional documents to make the process faster. If all documents have been uploaded just wait we will check it.
          <br />For any additional questions please call us: +44 (0)20 7740 0000
        </span>}
        onSuccess={this.onSuccess}
        showForm={this.state.showForm}
        reqs={reqs}
        loading={loading}
      />);
    /* eslint-enable max-len */
  }

  render() {
    return (
      <div className="tab-content platform-tabs">
        <section className="tab-pane fade in active">
          {this.renderForm()}
        </section>
      </div>);
  }
}
