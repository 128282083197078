import React from 'react';
import UpdatableComponent from './../../base/components/updatable-component';
import serviceLocator from '../../base/service/service-locator.js';
import MakeTransferStep1 from './make-transfer-step1';
import MakeTransferStep2 from './make-transfer-step2';
import ModalBookingTrade from './modal-booking-trade';

export default class MakeTransfer extends UpdatableComponent {

  state = {
    showMakeTransfer: true,
    step1Complete: false,
    step2Initialised: false,
    step2Complete: false,
    step3Complete: false,
    tradeData: null,
    sendOutInitialValues: null, // initial data for send out from small bene form
    sendOutData: null,
    quoteValues: null,
    bookingInProgress: false,
  };

  utils = serviceLocator.get('Utils');
  ga = serviceLocator.get('GA');

  onSendOutValuesChange = (sendOutValues) => {
    this.setState({ sendOutInitialValues: sendOutValues });
  };

  onStartBooking = (quoteValues) => {
    this.setState({
      bookingInProgress: true,
      quoteValues,
    });
  };

  onBookingError = () => {
    this.setState({
      bookingInProgress: false,
    });
  };

  onStep1Complete = (tradeData) => {
    // eslint-disable-next-line no-param-reassign
    tradeData.amount = parseFloat(tradeData.amount);
    this.setState({
      step1Complete: true,
      step2Initialised: false,
      tradeData,
    });
    this.utils.scrollScreenTo();
  };

  onStep2Initialised = () => {
    this.setState({
      step2Initialised: true,
      bookingInProgress: false,
    });
  };

  onStep2Complete = (sendOutData) => {
    const documentsRequired = false;
    this.setState({
      step2Complete: true,
      step3Complete: !documentsRequired,
      sendOutData,
      bookingInProgress: false,
      sendOutInitialValues: null,
    });
    this.utils.scrollScreenTo();
  };

  onNewTransferClick = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      showMakeTransfer: false,
      step1Complete: false,
      step2Complete: false,
      step3Complete: false,
      tradeData: null,
      sendOutData: null,
      quoteValues: null,
    }, () => {
      this.setState({
        showMakeTransfer: true,
      });
      this.utils.scrollScreenTo();
    });
  };

  renderSuccessMessage() {
    this.ga.logPageView('/transfer-success');

    return (
      <div className="make-transfer-step make-transfer-step4">
        <div className="clearfix">
          <div className="col-md-8 col-md-offset-2 make-transfer-documents">
            <h2>Thank you for choosing Currency Solutions.</h2>
            <h3>Your transaction was successful.<br />You will shortly
            receive a confirmation email.</h3>
            {/* eslint-disable max-len */}
            <br />
            <p>
              If you have opted to pay by bank transfer, our account
              details will be mentioned in the confirmation email.
            </p>
            <p>
              We will keep you updated on the progress of
              your currency transfer with us.
            </p>
            <p>
              We look forward to helping you again!
            </p>
            {/* eslint-enable max-len */}

            <div className="row btn-wrap">
              <div className=" col-md-offset-3 col-sm-6">
                <button
                  type="button"
                  className="btn btn-lg btn-primary btn-block btn-make-new-payment"
                  onClick={this.onNewTransferClick}
                >Make another transfer</button>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }

  render() {
    return (
      <div className="tab-content platform-tabs">
        {
          this.state.showMakeTransfer
          ? <section className="tab-pane fade in active tab-make-transfer" id="tab-make-transfer">
            {
              !this.state.step1Complete
              ? <MakeTransferStep1
                  onStepComplete={this.onStep1Complete}
                  onSendOutValuesChange={this.onSendOutValuesChange}
                  onStartBooking={this.onStartBooking}
                  onBookingError={this.onBookingError}
                  location={this.props.location}
                />
              : null
            }

            {
              (this.state.step1Complete && this.state.tradeData && !this.state.step2Complete)
              ? <MakeTransferStep2
                step2Complete={this.onStep2Complete}
                onFinishInitialisation={this.onStep2Initialised}
                currency={this.state.tradeData.currency}
                amount={parseFloat(this.state.tradeData.amount)}
                piId={this.state.tradeData.paymentId}
                initialValues={this.state.sendOutInitialValues}
              />
              : null
            }

            {
              this.state.step3Complete
              ? this.renderSuccessMessage()
              : null
            }

            {
              this.state.quoteValues
              ? <ModalBookingTrade
                  buyAmount={this.state.quoteValues.buyAmount}
                  buyCurrency={this.state.quoteValues.buyCurrency.value ||
                    this.state.quoteValues.buyCurrency}
                  sellAmount={this.state.quoteValues.sellAmount}
                  sellCurrency={this.state.quoteValues.sellCurrency.value ||
                    this.state.quoteValues.sellCurrency}
                  showModal={this.state.bookingInProgress}
                />
              : null
            }
          </section>
          : null
        }
      </div>
    );
  }
}
