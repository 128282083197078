import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Form from 'react-formal';
import yup from 'yup';
import zxcvbn from 'zxcvbn';
import Config from 'Config';
import serviceLocator from './../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import FormGroup from './../../app/components/form-group';
import PasswordStrengthMeter from './../../base/components/password-strength-meter';
import PhoneVerifyGroup, { schema as PhoneVerifyGroupSchema }
from './../../phone-verify/components/phone-verify-form-group';

export default class UserPassword extends FormComponent {
  constructor(props) {
    const state = {
      formSuccessMessage: false,
      passwordScore: null,
      showSmsConfirmation: false,
      formEnabled: false,
    };
    const schema = {
      currentPassword: yup.string().required('Please enter old password'),
      newPassword: yup.string()
      .required('Please enter new password')
      .min(6, 'New password must be at least 6 characters')
      .test('Password strength', 'Provided password is not secure', value => {
        const passwordScore = zxcvbn(value).score;
        this.setState({ passwordScore });
        return passwordScore >= 1;
      }),
      confirmPassword: yup.string()
      .required('Please repeat new password')
      .min(6, 'New password must be at least 6 characters')
      .test('passwordEquals', 'Passwords are not equal', function passwordEqualsTest(value) {
        return value === this.parent.newPassword;
      }),
      ...PhoneVerifyGroupSchema,
    };

    super(props, state, schema);
  }

  formOnChange = (value, updatedPaths, onValueUpdateHandler = null) => {
    this._formOnChange(value, updatedPaths, () => {
      if (onValueUpdateHandler) {
        onValueUpdateHandler();
      }
      if (!this.state.showSmsConfirmation && value.currentPassword !== '') {
        this.setState({ showSmsConfirmation: true });
      }
      if (updatedPaths.indexOf('newPassword') !== -1) {
        this.validateFields(['newPassword']);
      }
      if (
        updatedPaths.indexOf('newPassword') !== -1 &&
        this.state.value.confirmPassword
      ) {
        this.validateFields(['confirmPassword']);
      }
    });
  };

  formOnSubmit = (fields) => {
    const changePasswordGateway = serviceLocator.get('ChangePasswordGateway');
    this.setState({
      formSuccessMessage: false,
    });
    changePasswordGateway
    .post({
      currentPassword: fields.currentPassword,
      newPassword: fields.newPassword,
      newPasswordRepeat: fields.confirmPassword,
    })
    .then(response => {
      this.setState({
        formSuccessMessage: response.message,
        value: {
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        },
        formSuccess: {},
      });
    })
    .catch(error => { this._onFailResponse(error); });
  };

  onMobileConfirmationDone = () => {
    this.setState({ showSmsConfirmation: false });
  };

  onToggleEdit = e => {
    e.preventDefault();
    this.setState(state => {
      return {
        ...state,
        formEnabled: !state.formEnabled,
      };
    });
  };

  renderSmsConfirmation() {
    return this.state.showSmsConfirmation
    ? <div className="form-group form-group-row">
    <label htmlFor="mobileField" className="label-col">Confirm mobile</label>
    <FormGroup
      formGroupClassName="input-col form-group-mobile"
      fieldName="mobile"
      icons
      >
      <PhoneVerifyGroup
        verifyUser={true}
        setUserVerificationDone={this.onMobileConfirmationDone}
        verifyLabel="Confirm"
        verifiedLabel="Confirmed"
        modalTitle="Please confirm mobile number"
        />
    </FormGroup>
  </div>
  : null;
}

renderPasswordFieldset() {
  const { formEnabled } = this.state;
  return (
    <fieldset className={formEnabled ? 'form-enabled' : null}>
      <h2>Password <a href={'#'} onClick={this.onToggleEdit}>change password</a></h2>
      <FormGroup
        formGroupClassName="form-group-sm"
        fieldName="currentPassword"
        icons
        >
        <label htmlFor="currentPassword">Old password</label>
        <Form.Field
          type="password"
          name="currentPassword"
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
          className="form-control input-sm"
          disabled={!formEnabled}
          />
      </FormGroup>
      <FormGroup
        formGroupClassName="form-group-sm"
        fieldName="newPassword"
        icons
        >
        <label htmlFor="newPassword">New password</label>
        <Form.Field
          type="password"
          name="newPassword"
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
          className="form-control input-sm"
          disabled={!formEnabled}
          />
        <PasswordStrengthMeter passwordScore={this.state.passwordScore} />
      </FormGroup>
      <FormGroup
        formGroupClassName="form-group-sm"
        fieldName="confirmPassword"
        icons
        >

        <label htmlFor="confirmPassword">Confirm password</label>
        <Form.Field
          type="password"
          name="confirmPassword"
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
          className="form-control input-sm"
          disabled={!formEnabled}
          />
      </FormGroup>
      {this.renderSmsConfirmation()}
      <div className="form-group">
        <div className="input-col input-col-offset">
          <ReactCSSTransitionGroup
            component="div"
            transitionName="form-group-message"
            transitionEnterTimeout={Config.transitionTimeout.opacity}
            transitionLeaveTimeout={Config.transitionTimeout.opacity}
            >
            {
              this.state.formSuccessMessage
              ? <div
              className="form-group-message alert-success"
              >{this.state.formSuccessMessage}</div>
            : null
          }
        </ReactCSSTransitionGroup>
      </div>
    </div>
    {formEnabled
      ? <div className="form-group">
      <div className="row">
        <div className="col-md-10 col-md-offset-1">
          <Form.Button
            type="submit"
            className="btn btn-primary btn-lg btn-block"
            >Save changes</Form.Button>
        </div>
      </div>
    </div>
    : null
  }
</fieldset>
);
}

render() {
  return (
    <Form
      ref="form"
      component="div"
      className="form"
      schema={this.schema}
      value={this.state.value}
      errors={this.state.formErrors}
      onError={this.formOnError}
      onChange={this.formOnChange}
      onSubmit={this.formOnSubmit}
      >
      {this.renderPasswordFieldset()}
    </Form>
  );
}
}
