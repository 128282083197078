import React from 'react';
import classNames from 'classnames';
import serviceLocator from './../../base/service/service-locator';
import FormattedCurrency from './../../base/components/formatted-currency';
import Loader from './../../app/components/loader';
import Tooltip from './../../app/components/tooltip';
import Config from 'Config';

const QuoteTable = (props) => {
  const {
    quoteAvailable,
    quoteValues,
    quoteTimeout,
    quoteLoading,
    creditCardChargeApplied,
    sendOutAmount,
    estimatedDate,
    justify,
    showRate,
  } = props;
  const { getFormatedTimer } = serviceLocator.get('UtilsDate');
  const utilsDate = serviceLocator.get('UtilsDate');
  const timerClassNames = classNames({
    'text-danger': quoteTimeout <= 30,
  });

  let table = null;
  let crossBorderRow = null;
  let creditCardRow = null;
  let buy = null;
  let estimatedValueDate = null;
  let rate = null;
  let sell = null;
  let total = null;
  let fee = null;

  if (!quoteAvailable || !quoteValues) {
    return null;
  }

  if (Config.payon.CreditCardEnabled && quoteValues.fees && creditCardChargeApplied) {
    creditCardRow = (
      <tr>
        <td className={`${justify ? 'text-left' : 'text-right'}`}>Credit card charge</td>
        <td className={`${justify ? 'text-right' : 'text-left'}`}>
          <strong>
            <FormattedCurrency
              value={quoteValues.fees.creditCard}
              currency={quoteValues.sellCurrency}
            />
          </strong>
        </td>
      </tr>
    );
  }

  if (sendOutAmount) {
    buy = (
      <tr className="send-out-amount">
        <td className={`${justify ? 'text-left' : 'text-right'}`}>Buy Amount</td>
        <td className={`${justify ? 'text-right' : 'text-left'}`}>
          <strong>
            <FormattedCurrency
              value={quoteValues.buyAmount}
              currency={quoteValues.buyCurrency}
            />
          </strong>
        </td>
      </tr>
    );
  }

  if (showRate) {
    rate = (
      <tr>
        <td className={`${justify ? 'text-left' : 'text-right'}`}>Exchange rate</td>
        <td className={`${justify ? 'text-right' : 'text-left'}`}>
          {quoteValues.rate} {quoteValues.ratePair}
          <span className="inverted-rate hidden-xs hidden-sm">
                ({quoteValues.invertedRate} {quoteValues.invertedRatePair})
              </span>
        </td>
      </tr>

    );
  }

  sell = (
    <tr className="sell-row">
      <td className={`${justify ? 'text-left' : 'text-right'}`}>Sell Amount</td>
      <td className={`${justify ? 'text-right' : 'text-left'}`}>
          <FormattedCurrency
            value={quoteValues.sellAmount}
            currency={quoteValues.sellCurrency}
          />
      </td>
    </tr>
  );

  total = (
    <tr className="total-cost">
      <td className={`${justify ? 'text-left' : 'text-right'}`}>Total cost</td>
      <td className={`${justify ? 'text-right' : 'text-left'}`}>
        <strong>
          <FormattedCurrency
            value={quoteValues.totalAmount}
            currency={quoteValues.sellCurrency}
          />
        </strong>
      </td>
    </tr>
  );

  fee = (
    <tr className="fees">
      <td className={`${justify ? 'text-left' : 'text-right'}`}>Transfer fee</td>
      <td className={`${justify ? 'text-right' : 'text-left'}`}>
        <FormattedCurrency
          value={quoteValues.fees.transfer}
          currency={quoteValues.sellCurrency}
        />
      </td>
    </tr>
  )

  if (estimatedDate) {
    estimatedValueDate = (
      <tr>
        <td className={`${justify ? 'text-left' : 'text-right'}`}>Estimated Transfer Date</td>
        <td className={`${justify ? 'text-right' : 'text-left'}`}>
          {utilsDate.getUKDate(estimatedDate)}
          <Tooltip
            text={'Subject to bank holidays. ' +
                    'Any change will be notified on the Trade Confirmation note.'}
          />
        </td>
      </tr>
    );
  }

  const delimiter = (
    <tr className="delimiter">
      <td> </td>
      <td> </td>
    </tr>
  )

  if (quoteAvailable) {
    table = (
      <table className="table">
        <colgroup>
          <col className="col1" />
          <col className="col2" />
        </colgroup>
        <tbody>
          {sell}
          {fee}
          {crossBorderRow}
          {creditCardRow}
          {total}

          {delimiter}

          {rate}
          {estimatedValueDate}
          {buy}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" className="quote-timer text-center">
              <span className="hidden-xs hidden-sm">
                <span className={timerClassNames}>
                  Quote will expire in {getFormatedTimer(quoteTimeout)}
                </span>
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    );
  }


  return (
    <div className="make-transfer-rate clearfix row">
      <div className="col-md-8 col-md-offset-2">
        {quoteLoading ? <Loader /> : null}

        <div className="table-responsive table-quote-wrap">
          {table}

          {false && quoteValues.bookTradeAllowed ?
            <div className="table-quote-info">Please
              &nbsp;
              complete payment details&nbsp;
              on the next step.
            </div>
          : null}
        </div>
      </div>
    </div>);
};
QuoteTable.propTypes = {
  quoteAvailable: React.PropTypes.bool.isRequired,
  quoteValues: React.PropTypes.object,
  quoteTimeout: React.PropTypes.number,
  quoteLoading: React.PropTypes.bool.isRequired,
  creditCardChargeApplied: React.PropTypes.bool.isRequired,
  sendOutAmount: React.PropTypes.bool,
  showRate: React.PropTypes.bool,
  estimatedDate: React.PropTypes.string,
  justify: React.PropTypes.bool,
};
QuoteTable.defaultProps = {
  justify: false,
  showRate: true,
};

export default QuoteTable;
