import React from 'react';
import { Link } from 'react-router';
import Form from 'react-formal';
import Select from 'react-select';
import yup from 'yup';
import serviceLocator from '../../base/service/service-locator';
import FormGroup from './../../app/components/form-group';
import SendOutAbstract, { newBeneficiary } from '../../payment/components/send-out-abstract';
import SendOutFieldset,
  { schema as sendOutSchema } from './../../payment/components/send-out-fieldset';
import { schema as beneficiarySchema } from './../../beneficiary/components/beneficiary-form';
import { schema as bankDetailsSchema } from './../../beneficiary/components/bank-details-form';
// eslint-disable-next-line max-len
import { schema as phoneVerifySchema } from './../../phone-verify/components/phone-verify-form-group';
import Loader from './../../app/components/loader';

export default class MakeTransferStep2 extends SendOutAbstract {

  static propTypes = {
    piId: React.PropTypes.number,
    currency: React.PropTypes.string.isRequired,
    amount: React.PropTypes.number.isRequired,
    step2Complete: React.PropTypes.func,
    initialValues: React.PropTypes.object,
    onFinishInitialisation: React.PropTypes.func,
  };

  utils = serviceLocator.get('Utils');

  constructor(props) {
    const state = {
      isFormSubmiting: false,
      isInitialising: true,
    };

    const schema = {
      id: yup.number().default(0),
      currency: yup.object().default({ value: props.currency, label: props.currency }),
      amount: yup.string().default(props.amount),
      beneficiaryId: yup.object().default(newBeneficiary),
      ...beneficiarySchema,
      ...sendOutSchema,
      ...bankDetailsSchema,
      ...phoneVerifySchema,
    };

    super(props, state, schema);
  }

  loadData() {
    this.ga.logPageView('/transfer-send-out');

    this.setState({
      isLoading: true,
    });

    super.loadData();

    if (this.props.piId) {
      this.loadById(
        parseInt(this.props.piId, 10),
        ['Submitted', 'New'],
        () => {
          this.processInitialValues();
        }
      );
    } else {
      this.processInitialValues();
    }
  }

  processInitialValues() {
    if (this.props.initialValues &&
      this.props.initialValues.beneficiaryId.value !== newBeneficiary.value
    ) {
      const newValues = Object.assign({}, this.state.value, {
        beneficiaryId: this.props.initialValues.beneficiaryId,
        reference1: this.props.initialValues.reference1,
        reason: this.props.initialValues.reason,
        otherReason: this.props.initialValues.otherReason,
      });
      this.formOnSubmit(newValues);
    } else {
      this.finishInitialisation();
    }
  }

  finishInitialisation() {
    this.setState({ isInitialising: false });
    if (this.props.onFinishInitialisation) {
      this.props.onFinishInitialisation();
    }
  }

  onChangeCurrency = () => {
    this._onChangeCurrency();
    const editedPi = this.getEditedPi(this.props.amount);

    if (editedPi && editedPi.value) {
      this.setFieldsValues(editedPi.value);
    }
  };

  doesFieldTriggerSuccess = (field) => {
    if (field === 'mobile') {
      return false;
    }
    return true;
  };

  formOnSubmit = (fields) => {
    const formattedFields = Object.assign({}, fields);
    if ('amount' in formattedFields) {
      formattedFields.amount = this.utils.formatAmount(formattedFields.amount).value;
    }

    this.setState({ isFormSubmiting: true });

    this.formOnSubmitAbstract(formattedFields, (successResponse) => {
      this.finishFormSubmiting();
      if (this.props.step2Complete) {
        this.props.step2Complete(successResponse);
      }
    }, () => {
      this.finishFormSubmiting();
      this.finishInitialisation();
    });
  };

  formOnChange = this.formOnChangeAbstract;

  finishFormSubmiting() {
    this.setState({ isFormSubmiting: false });
    this.utils.scrollScreenTo();
  }

  render() {
    return (
      <div className="make-transfer-step make-transfer-step2">
        <div className="clearfix">
          <div className="col-sm-8 col-sm-offset-2">
            <ul className="make-transfer-steps">
              <h3 className="active">Select your recipient</h3>
            </ul>
          </div>
        </div>

        <div className="clearfix">
          {this.state.isInitialising ? <Loader /> : <Form
            component="div"
            ref="form"
            schema={this.schema}
            value={this.state.value}
            errors={this.state.formErrors}
            onError={this.formOnError}
            onChange={this.formOnChange}
            onSubmit={this.formOnSubmit}
            className="form clearfix"
            id="send-out-form"
          >
            <div className="col-sm-8 col-sm-offset-2">

              <div className="make-transfer-beneficiary-wrap">
                <div className="beneficiary-selector form-group row">
                  <label
                    htmlFor="beneficiarySelect"
                    className="label-col"
                  >Existing or Add New Beneficiary</label>

                  <FormGroup formGroupClassName="form-group-select input-col form-group-sm">
                    <Form.Field
                      type={Select}
                      id="beneficiarySelect"
                      name="beneficiaryId"
                      defaulValue={0}
                      className="input-sm"
                      placeholder="Select Beneficiary"
                      options={this.state.beneficiaryList}
                      clearable={false}
                      searchable={false}
                    />
                  </FormGroup>
                </div>

                <SendOutFieldset showOtherReason={this.state.showOtherReason} />
              </div>

              <div className="make-transfer-beneficiary-btns-wrap row">
                {/* eslint-disable react/no-unknown-property */}
                <Form.Message for="common" />
                {/* eslint-enable react/no-unknown-property */}
                {this.state.error ? <div>{this.state.error}</div> : null}

                <div className="col-sm-6 col-sm-offset-3">
                  <Form.Button
                    type="submit"
                    className="btn btn-block btn-lg btn-primary btn-finish-transfer"
                    disabled={this.state.isFormSubmiting}
                  >Finish</Form.Button>
                </div>
              </div>

              {this.state.isFormSubmiting ? <Loader /> : null}

              <div className="sumbit-later-label row">
                <Link to="/transfer-history">I will submit beneficiary details later</Link>
              </div>
            </div>
          </Form>}
        </div>
      </div>
    );
  }
}
