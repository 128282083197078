import React from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-formal';
import serviceLocator from './../../base/service/service-locator';
import FormComponent from '../../base/components/form-component';
import FormGroup from './../../app/components/form-group';
import FormMessage from './../../app/components/form-message';
import PhoneVerifyGroup, { schema as PhoneVerifyGroupSchema }
  from './../../phone-verify/components/phone-verify-form-group';
import CallUs from "../../base/components/call-us";
import GoogleAuthenticatorModal from './google-authenticator-modal'

export default class DeviceVerificationModal extends FormComponent {
  auth = serviceLocator.get('Auth');
  utils = serviceLocator.get('Utils');

  static propTypes = {
    show: React.PropTypes.bool,
    onVerify: React.PropTypes.func,
    skipConfirm: React.PropTypes.bool,
    secondFactor: React.PropTypes.string,
  };

  static defaultProps = {
    skipConfirm: false,
    secondFactor: 'sms',
  };

  constructor(props) {
    const state = {
      showModal: (typeof props.show !== 'undefined') ? props.show : true,
    };

    const schema = {
      ...PhoneVerifyGroupSchema,
    };

    super(props, state, schema);
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.isVerified !== nextState.isVerified && !nextState.isVerified) {
      this.utils.setBlurEffect();
    }
  }

  formOnSubmit = (fields) => {
    this._formOnSubmit(fields);

    // send fields to back-end on callback request
  };

  render() {
    const { onVerify, skipConfirm, secondFactor } = this.props;

    if (secondFactor === 'googleAuthenticator') {
      return (
        <GoogleAuthenticatorModal
          onVerificationSucceed={onVerify}
        />
      )
    }

    const form = (
      <Form
        ref="form"
        className="form form-enabled"
        schema={this.schema}
        value={this.state.value}
        errors={this.state.formErrors}
        onError={this.formOnError}
        onChange={this.formOnChange}
        onSubmit={this.formOnSubmit}
      >
        <div className="form-group form-group-row">
          <FormGroup
            formGroupClassName="input-col form-group-mobile"
            fieldName="mobile"
            icons
          >
            <PhoneVerifyGroup
              verifyUser={true}
              setUserVerificationDone={onVerify}
              verifyLabel="Confirm"
              verifiedLabel="Confirmed"
              modalTitle="Please confirm mobile number"
              skipConfirm={skipConfirm}
              reVerify={true}
            />
          </FormGroup>
        </div>

        <FormMessage context={this.getChildContext()} for="common" />
      </Form>
    );

    if (skipConfirm) {
      return form;
    }

    return (
      <Modal
        show={!this.state.isVerified}
        animation={false}
        bsSize="small"
        backdrop="static"
        keyboard={false}
        className="modal-login"
      >
        <Modal.Header>
          <div className="navbar-brand">CURRENCY SOLUTIONS</div>
          <Modal.Title>Confirm Mobile</Modal.Title>
        </Modal.Header>

          <Modal.Body>
            {form}
          </Modal.Body>

          <Modal.Footer>
            <CallUs message="If you can't recognise this number or have any questions, please call us" newLine={true} />
          </Modal.Footer>
      </Modal>);
  }
}
