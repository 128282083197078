import "core-js/stable";

export default class StaticDataModel {
  constructor(data = null) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  getElementByKey(key, value) {
    const el = this.data.find(v => v[key] === value);
    return el || value;
  }
}
